import { useCallback, useMemo } from 'react';
import { gql, QueryHookOptions, useQuery, useLazyQuery } from '@apollo/client';

import { MappedVentures, SuggestVenture, Venture } from 'src/interfaces/venture';
import { Option } from 'src/server-shared/inputs/select';
import { ventureGQLString, locationsGQLFields, venturesQuery } from './venture/venture.query';
export { venturesQuery };

export const suggestedVenturesQuery = gql`
  query {
    suggestedUserVentures {
      refId
      id
      name
      locations {
        ${locationsGQLFields}
      }
    }
  }
`;

export const ventureQuery = gql`
  query venture($refId: String) {
    venture(ventureExternalId: $refId) {
      id
      name
      refId
      locations {
        city
        country
        postalCode
        street
      }
      registrationCountry
      managingDirectors {
        firstName
        lastName
      }
      incorporationDate
      officialSubject
    }
  }
`;

export const filteredVentures = gql`
  query ventures($ventureName: String, $ventureCountry: String) {
    ventures(filter: { ventureName: $ventureName, ventureCountry: $ventureCountry }) {
      refId
      name
      locations {
        city
        country
        postalCode
        street
      }
    }
  }
`;

export const filteredVenturesByIds = gql`
  query ventures($ventureIds: [Long]) {
    ventures(filter: { ventureIds: $ventureIds }) {
      ${ventureGQLString}
    }
  }
`;

export const ventureMapper = (item: any): Option => {
  return { value: item.registryReferenceId, label: item.name, data: { item } };
};

export function useGetVentures(params?: QueryHookOptions) {
  const { data, loading, refetch } = useQuery<{ ventures: Venture[] }>(venturesQuery, params);

  const ventureOptions = useMemo(() => {
    if (data && data.ventures) {
      return data.ventures.map(ventureMapper);
    }
    return [];
  }, [data]);

  return {
    ventures: data?.ventures || [],
    ventureOptions,
    loading,
    refetch,
  };
}

export const useGetVentureByRefId = (params?: QueryHookOptions) => {
  const [getVentureQuery, { loading }] = useLazyQuery(ventureQuery, params);

  const getVenture = useCallback(
    async (refId) => {
      const {
        data: { venture },
      } = await getVentureQuery({ variables: { refId }, fetchPolicy: 'no-cache' });

      return venture;
    },
    [getVentureQuery],
  );

  return { getVenture, loading };
};

export function useGetSuggestedVentures(params?: QueryHookOptions) {
  const { data, loading } = useQuery<{ suggestedUserVentures: SuggestVenture[] }>(
    suggestedVenturesQuery,
    params,
  );

  return {
    ventures: data?.suggestedUserVentures || [],
    loading,
  };
}

export const useGetFilteredVentures = (ventureIds: number[], params?: QueryHookOptions) => {
  const { data, loading } = useQuery<{ ventures: Venture[] }>(filteredVenturesByIds, {
    variables: { ventureIds },
    ...params,
  });

  const mappedVentures = useMemo(() => {
    if (!data) {
      return {};
    }
    return data.ventures.reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr };
    }, {} as MappedVentures);
  }, [data]);

  return {
    ventures: data?.ventures || [],
    loading,
    mappedVentures,
  };
};

export function useGetUserVentures(params?: QueryHookOptions) {
  const { data, loading, refetch } = useQuery<{ ventures: Venture[] }>(venturesQuery, params);
  return {
    ventures: data?.ventures || [],
    loading,
    refetch,
  };
}
