import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AQString } from 'src/shared/AQString';
import {
  HeaderStepIndicatorRefObject,
  StepperType,
} from 'src/server-shared/header/header-step-indicator/types';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { ServerSharedText } from 'src/server-shared/texts/text';
import classes from './index.module.scss';
import { ServerSharedHeaderStepIndicator } from 'src/server-shared/header/header-step-indicator';
import { classNames } from 'src/utils/classNames';
import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import useElementSize from 'src/hooks/useElementSize';
import { Modes } from '..';
import { HEADER_HEIGHT } from 'src/styles';
import { ReviewModal } from 'src/server-shared/data-room-review/modals/review-modal';
import { useToggle } from 'src/hooks/useToggle';
import { useRouter } from 'next/router';
import {
  DATA_ROOM_SETUP_PAGE,
  DUE_DILIGENCE_PAGE,
  VENTURE_PITCH_SETUP_PAGE,
} from 'src/constants/paths';
import { SubmitActionButton } from 'src/ui/offering-setup/venture-pitch/components/submit-action';
import { SubmitDueDiligence } from 'src/ui/equity-offerings/data-room-evaluation/components/action-button';
import { setHeaderStepper } from 'src/store/app/reducer';
import { useDispatch } from 'react-redux';
import { HeaderButtons } from 'src/constants/common';

interface HeaderStepperProps {
  steps: StepIndicatorObject[];
  title: string;
  mode: Modes;
  titleButtonText?: string;
}

export const HeaderStepper = ({ steps, title, titleButtonText, mode }: HeaderStepperProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openReviewModal, handleToggleReviewModal] = useToggle();
  const router = useRouter();
  const { offeringId } = router.query as { offeringId: string };

  const stepIndicatorRef = useRef<HeaderStepIndicatorRefObject>(null);
  const [stepElement, setStepElement] = useState<StepperType>('breadcrumbs');
  const headerStepsWidth = useRef(0);
  const [leftElementRef, { width }] = useElementSize();

  const onResize = useCallback(() => {
    if (
      stepIndicatorRef &&
      'current' in stepIndicatorRef &&
      stepIndicatorRef.current &&
      stepIndicatorRef.current.stepsListRef.current
    ) {
      const headerStepIndicatorWidth =
        stepIndicatorRef.current.stepsListRef.current.offsetWidth || 0;
      headerStepsWidth.current = headerStepIndicatorWidth;
    }

    const showBreadcrumbs = window.innerWidth - 64 - width - headerStepsWidth.current >= 16;
    if (showBreadcrumbs && stepElement === 'dropdown') {
      setStepElement('breadcrumbs');
      return;
    }
    if (!showBreadcrumbs && stepElement === 'breadcrumbs') {
      setStepElement('dropdown');
      return;
    }
  }, [stepIndicatorRef, stepElement, width]);

  useIsomorphicLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [onResize, steps.length]);

  const button = useMemo(() => {
    if (router.pathname === VENTURE_PITCH_SETUP_PAGE) {
      return <SubmitActionButton isVenturePitch />;
    }
    if (router.pathname === DATA_ROOM_SETUP_PAGE) {
      return <SubmitActionButton />;
    }
    if (router.pathname === DUE_DILIGENCE_PAGE) {
      return <SubmitDueDiligence />;
    }
    return undefined;
  }, [router.pathname]);

  useIsomorphicLayoutEffect(() => {
    const wrapper = document.getElementById('__next');
    const appContainer = wrapper?.firstChild as HTMLElement | undefined;
    if (appContainer && !!title) {
      appContainer.style.gridTemplateRows = `[header-start] ${
        HEADER_HEIGHT * 2
      }px [header-end content-start] 1fr [content-end]`;
    }
    return () => {
      if (appContainer) {
        appContainer.style.gridTemplateRows = `[header-start] ${HEADER_HEIGHT}px [header-end content-start] 1fr [content-end]`;
      }
    };
  }, [title]);

  const onChange = useCallback(
    (v: StepIndicatorObject) => {
      dispatch(
        setHeaderStepper({
          steps: steps.map((el) => ({
            ...el,
            active: el.value === v.value,
          })),
        }),
      );
    },
    [dispatch, steps],
  );

  const titleButton = useMemo(() => {
    if (titleButtonText === HeaderButtons.RESET) {
      const resetTermSheetEvent = () => document.body.dispatchEvent(new Event('resetTermSheet'));
      return (
        <ServerSharedText
          clickable
          type="label"
          color="alert600"
          className={classes['button-margin']}
          onClick={resetTermSheetEvent}
        >
          <AQString componentId="aquaty-generic.cta.reset" />
        </ServerSharedText>
      );
    }
    return null;
  }, [titleButtonText]);

  if (!title) {
    return null;
  }

  return (
    <>
      <div />
      <div className={classNames('flex justify-content-sp', classes.wrapper)}>
        <div className="flex justify-content-start" ref={leftElementRef}>
          <ServerSharedText type={mode === 'stepper' ? 'label-s' : 'label-l'} as="p">
            {t(title)}
          </ServerSharedText>
          {titleButton}
        </div>

        <ServerSharedHeaderStepIndicator
          steps={steps}
          type={stepElement}
          pageName={title}
          ref={stepIndicatorRef}
          button={button}
          onChange={onChange}
        />
      </div>
      {openReviewModal && (
        <ReviewModal show closeEvent={handleToggleReviewModal} id={+offeringId} />
      )}
    </>
  );
};
