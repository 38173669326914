import { MouseEventHandler } from 'react';
import { FormItem, Hook } from 'hook-easy-form';

import { Roles, User, UserAssignment } from 'src/interfaces/user';
import { FileField } from 'src/interfaces/common';
import { DataRoomEvaluationSteps } from 'src/interfaces/data-room-evaluation';
import {
  EquityOffering,
  EquityOfferingParticipants,
  EquityOfferingTaskStatus,
} from 'src/interfaces/offering';
import { PaletteKeys } from 'src/styles/palette';

export interface IStepProps {
  id: string;
  goToNextStep: (d?: any) => void;
  goToBack: MouseEventHandler<HTMLButtonElement>;
  showError: () => void;
  formProps: AssignPartnerForm;
  carryParticipationAvailable: number;
  equityOfferingParticipants: EquityOfferingParticipants[];
  equityOffering: EquityOffering;
  isAdmin?: boolean;
  user: User | null;
  role: Roles | undefined;
}

export const form = [
  {
    name: 'ventureScout',
    value: {},
    required: true,
  },
  {
    value: '',
    name: 'carryParticipationVentureScout',
    options: {
      type: 'number',
      marginBottom: false,
      step: 1,
      label:
        'aquaty-app.administration.equity-offering.assign-partner.venture-scout.label-carry-participation',
      prefix: '%',
    },
  },
  {
    name: 'generalPartner',
    value: {},
    required: true,
  },
  {
    value: null,
    name: 'carryParticipationGeneralPartner',
    options: {
      type: 'number',
      marginBottom: false,
      step: 1,
      label:
        'aquaty-app.administration.equity-offering.assign-partner.general-partner.label-carry-participation',
      prefix: '%',
    },
  },
  {
    name: 'descriptionGeneralPartner',
    value: '',
    options: {
      label:
        'aquaty-app.administration.equity-offering.assign-partner.general-partner.label-say-something-more',
      placeholder:
        'aquaty-app.administration.equity-offering.assign-partner.general-partner.placeholder-say-something-more',
      maxLength: 240,
    },
  },
  {
    name: 'expertPartners',
    value: [],
    options: {},
  },
] as FormItem<FormData>[];

export type FormData = {
  ventureScout: EquityOfferingParticipants;
  carryParticipationVentureScout: string;
  generalPartner: EquityOfferingParticipants;
  carryParticipationGeneralPartner: string;
  descriptionGeneralPartner: string;
  expertPartners: EquityOfferingParticipants[];
};

export type AssignPartnerForm = Omit<Hook<FormData>, 'multipleFieldUpdate'>;

export const getUserValue = (user: User | UserAssignment) => {
  const id = 'userId' in user ? user.userId : user.id;
  return `${user.fullName},${user.email},id=${id}`;
};
export const userMapper = (
  userAssignmentProposals: UserAssignment[],
  equityOfferingParticipants: EquityOfferingParticipants[],
) => {
  const userStatuses = equityOfferingParticipants.reduce((acc, curr) => {
    return { ...acc, [curr.participantAccreditationDetails.user.userId]: curr.acceptanceStatus };
  }, {} as { [key: number]: EquityOfferingTaskStatus });

  const userAssignmentProposalsMapper = (el: UserAssignment) => ({
    value: getUserValue(el),
    label: el.fullName as string,
    image: el.image as FileField,
    data: { item: el },
    isDisabled:
      (userStatuses[el.id] || EquityOfferingTaskStatus.TO_COMPLETE) ===
      EquityOfferingTaskStatus.INSUFFICIENT,
    email: el.email as string,
    yourSelf: '',
    status: userStatuses[el.id],
  });

  return userAssignmentProposals.map(userAssignmentProposalsMapper);
};

export const addExpertCheckboxes = [
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.pitch-deck',
    value: DataRoomEvaluationSteps.PITCH_DECK,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.legal-company-foundation',
    value: DataRoomEvaluationSteps.LEGAL_COMPANY_FOUNDATION,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.previous-financing',
    value: DataRoomEvaluationSteps.PREVIOUS_FINANCING,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.team-shareholders',
    value: DataRoomEvaluationSteps.TEAM_AND_SHAREHOLDERS,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.product-demo',
    value: DataRoomEvaluationSteps.PRODUCT_AND_DEMO,
  },

  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.intellectual-property-assets',
    value: DataRoomEvaluationSteps.INTELLECTUAL_PROPERTY_AND_ASSETS,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.market-opportunity-current-traction',
    value: DataRoomEvaluationSteps.MARKET_OPPORTUNITY,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.business-model-growth-plan',
    value: DataRoomEvaluationSteps.BUSINESS_MODEL,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.market-distribution-strategy',
    value: DataRoomEvaluationSteps.DISTRIBUTION_AND_GROWTH_STRATEGY,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.financials',
    value: DataRoomEvaluationSteps.FINANCIALS,
  },
  {
    label:
      'aquaty-app.administration.equity-offering.assign-partner.general-partner-modal.checkbox.others',
    value: DataRoomEvaluationSteps.OTHERS,
  },
];

export const expertCheckboxesLabels = addExpertCheckboxes.reduce((acc, curr) => {
  return { ...acc, [curr.value]: curr.label };
}, {} as { [key: string]: string });

export interface PartnersCarryTable {
  id: number;
  fullName: string;
  image: FileField | null;
  role: JSX.Element | null;
  carryParticipation: number;
  comment: string | null;
  filteredEvaluationSections: DataRoomEvaluationSteps[];
  status: EquityOfferingTaskStatus;
}

export interface PartnersProfitTable {
  id: number;
  fullName: string;
  image: FileField | null;
  carryParticipation: number;
  profitParticipation: string | number;
}

export const roleAssignmentLabels = {
  [EquityOfferingTaskStatus.WAITING]: 'Invitation Pending',
  [EquityOfferingTaskStatus.COMPLETED]: 'Role Accepted',
  [EquityOfferingTaskStatus.INSUFFICIENT]: 'Role Denied',
  [EquityOfferingTaskStatus.LOCKED]: '',
  [EquityOfferingTaskStatus.TO_COMPLETE]: 'Invitation Pending',
  [EquityOfferingTaskStatus.TO_REWORK]: '',
};

export const roleAssignmentColors: Record<EquityOfferingTaskStatus, PaletteKeys> = {
  [EquityOfferingTaskStatus.WAITING]: 'warning600',
  [EquityOfferingTaskStatus.COMPLETED]: 'success600',
  [EquityOfferingTaskStatus.INSUFFICIENT]: 'alert600',
  [EquityOfferingTaskStatus.LOCKED]: 'grey900',
  [EquityOfferingTaskStatus.TO_COMPLETE]: 'warning600',
  [EquityOfferingTaskStatus.TO_REWORK]: 'grey900',
};

export const rolesLabel: Record<Roles, string> = {
  VENTURE_FOUNDER: 'aquaty-generic.cta.founder.title',
  INVESTOR: 'aquaty-generic.cta.investor',
  VENTURE_PARTNER: 'aquaty-generic.cta.general',
  EXPERT_PARTNER: 'aquaty-generic.cta.expert',
  VENTURE_SCOUT: 'aquaty-generic.cta.scout',
  AQUATY_STAFF: 'aquaty-generic.cta.admin',
  SOFT_REGISTERED_VENTURE_INVESTOR: 'aquaty-generic.cta.soft-registered',
  INVESTMENT_MANAGER: 'aquaty-generic.cta.investment-manager',
};

export enum DealTeamSetup {
  'VENTURE_SCOUT' = 'VENTURE_SCOUT',
  'GENERAL_PARTNER' = 'GENERAL_PARTNER',
  'EXPERT_PARTNERS' = 'EXPERT_PARTNERS',
  'SUMMARY' = 'SUMMARY',
}

const ventureScout = {
  value: DealTeamSetup.VENTURE_SCOUT,
  label: 'aquaty-app.administration.equity-offering.assign-partner.venture-scout',
  active: true,
  available: true,
  complete: false,
};

const commonDealTeamSetupSteps = [
  {
    value: DealTeamSetup.GENERAL_PARTNER,
    label: 'aquaty-generic.cta.venture-partner',
    active: false,
    available: false,
    complete: false,
  },
  {
    value: DealTeamSetup.EXPERT_PARTNERS,
    label: 'aquaty-app.administration.equity-offering.assign-partner.expert-partners',
    active: false,
    available: false,
    complete: false,
  },
  {
    value: DealTeamSetup.SUMMARY,
    label: 'aquaty-app.administration.equity-offering.assign-partner.summary',
    active: false,
    available: false,
    complete: false,
  },
];

export const getDealTeamSetupSteps = (isPrivateOffering: boolean) => {
  return isPrivateOffering ? [ventureScout, ...commonDealTeamSetupSteps] : commonDealTeamSetupSteps;
};
