import { BeneficialOwner } from 'src/interfaces/beneficialOwner';
/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import {
  IGetSingleUserParams,
  ISingleElement,
  Roles,
  SaveUserInvestmentAccount,
  User,
  UserIdentification,
  UserInvestmentAccount,
} from 'src/interfaces/user';
import { addNotification, setUserPaths } from '../app/reducer';
import { getCookie, setCookies } from 'cookies-next';
import { USER_ID } from 'src/constants/registration';
import { RoleAccreditationDetails } from 'src/interfaces/role-accreditation';

export const getSingleUserRole = (u: User) => {
  const isManager = u.userRoles.some((e) => e.role === Roles.INVESTMENT_MANAGER);
  return isManager ? Roles.INVESTMENT_MANAGER : u.userRoles[0]?.role;
};

const userMapper = (response: User) => {
  return { ...response, role: getSingleUserRole(response) };
};

const roleAccreditationMapper = (response: RoleAccreditationDetails) => {
  return { ...response, userRole: response.user ? getSingleUserRole(response.user) : undefined };
};

export const getUserId = () => {
  return getCookie(USER_ID);
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'User',
    'Role-Accreditation',
    'Identification-Details',
    'Investment-Accounts',
    'Beneficial-Owners',
  ],
  endpoints: (builder) => ({
    getUser: builder.query<User, null>({
      query: () => ({
        url: `/users/${getCookie(USER_ID)}`,
      }),
      transformResponse: userMapper,
      providesTags: [{ type: 'User', id: 'currentUser' }],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserPaths((data || {}) as User));
          // dispatch(setActiveNavigationItem(router.pathname));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {}
      },
    }),

    createUser: builder.mutation<
      User,
      { user: Partial<User>; params: { invitationToken: string } | { invitationToken?: undefined } }
    >({
      query: (data) => {
        const { user, params } = data;
        return {
          url: '/users',
          method: 'POST',
          data: user,
          params,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'));
        try {
          const { data } = await queryFulfilled;
          setCookies(USER_ID, data.userId);
          dispatch(
            userApi.util.updateQueryData('getUser', null, (draft) => {
              if (draft.userId === Number(arg.user.userId)) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch (err: any) {
          // `onError` side-effect
          dispatch(
            addNotification({
              message: err?.error?.data?.toString(),
              type: 'error',
              title: 'Error',
            }),
          );
        }
      },
      invalidatesTags: [{ type: 'User', id: 'currentUser' }],
    }),

    updateUser: builder.mutation<User, { id: number; value: Partial<User> }>({
      query: (data) => {
        const { value } = data;
        return {
          url: `/users/${data.id}`,
          method: 'PUT',
          data: value,
        };
      },
      transformResponse: userMapper,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'));
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.log('ee', err);
          // `onError` side-effect
          dispatch(
            addNotification({
              message: err?.error.data.toString(),
              type: 'error',
              title: 'Error',
            }),
          );
        }
      },
      invalidatesTags: [{ type: 'User', id: 'currentUser' }],
    }),

    getSingleUser: builder.query<ISingleElement<User>, IGetSingleUserParams>({
      query: ({ id }) => ({
        url: `/users/${id}`,
      }),
      providesTags: (result) => (result ? [{ type: 'User', id: result.data.userId }] : []),
    }),

    getUserRoleAccreditation: builder.query<
      RoleAccreditationDetails,
      { id: string | number } | undefined
    >({
      query: (payload) => ({
        url: `/users/${payload?.id || getCookie(USER_ID)}/role-accreditation-details`,
      }),
      transformResponse: roleAccreditationMapper,
      providesTags: (result) =>
        result ? [{ type: 'Role-Accreditation', id: 'currentUserRoleAccreditation' }] : [],
    }),

    updateRoleAccreditation: builder.mutation<
      RoleAccreditationDetails,
      { id: number; value: Partial<RoleAccreditationDetails> }
    >({
      query: (data) => {
        const { value } = data;
        return {
          url: `/users/${data.id}/role-accreditation-details`,
          method: 'PUT',
          data: value,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'));
        try {
          await queryFulfilled;
        } catch (err: any) {
          // `onError` side-effect
          dispatch(
            addNotification({
              message: err?.error.data.toString(),
              type: 'error',
              title: 'Error',
            }),
          );
        }
      },
      invalidatesTags: [{ type: 'Role-Accreditation', id: 'currentUserRoleAccreditation' }],
    }),

    getUserIdentification: builder.query<UserIdentification, { id: string | number } | undefined>({
      query: (payload) => ({
        url: `/users/${payload?.id || getCookie(USER_ID)}/identification-details`,
      }),
      providesTags: (result) =>
        result ? [{ type: 'Identification-Details', id: 'currentUserRoleAccreditation' }] : [],
    }),

    getUserInvestmentAccount: builder.query<
      UserInvestmentAccount[],
      { id: string | number } | undefined
    >({
      query: (payload) => ({
        url: `/users/${payload?.id || getCookie(USER_ID)}/investment-accounts`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el: UserInvestmentAccount) => ({
                type: 'Investment-Accounts' as const,
                id: el.investmentAccountId,
              })),
              { type: 'Investment-Accounts', id: 'LIST' },
            ]
          : [{ type: 'Investment-Accounts', id: 'LIST' }],
    }),

    saveUserInvestmentAccount: builder.mutation<
      UserInvestmentAccount,
      { userId: number; data: Partial<SaveUserInvestmentAccount> }
    >({
      query: ({ userId, data }) => {
        return {
          url: `/users/${userId}/investment-accounts`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Investment-Accounts' as const }],
    }),

    updateUserInvestmentAccount: builder.mutation<
      UserInvestmentAccount,
      { userId: number; investmentAccountId: number; data: Partial<SaveUserInvestmentAccount> }
    >({
      query: ({ userId, investmentAccountId, data }) => {
        return {
          url: `/users/${userId}/investment-accounts/${investmentAccountId}`,
          method: 'PUT',
          data,
        };
      },
      invalidatesTags: (result) => [
        { type: 'Investment-Accounts', id: result?.investmentAccountId || 'LIST' },
      ],
    }),

    useGetIABeneficialOwners: builder.query<
      BeneficialOwner[],
      { id: string | number; accountId: number }
    >({
      query: (payload) => ({
        url: `/users/${payload?.id}/investment-accounts/${payload?.accountId}/beneficial-owners`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el: BeneficialOwner) => ({
                type: 'Investment-Accounts' as const,
                id: el.beneficialOwnerId,
              })),
              { type: 'Beneficial-Owners', id: 'LIST' },
            ]
          : [{ type: 'Beneficial-Owners', id: 'LIST' }],
    }),

    deleteInvestmentAccount: builder.mutation<
      UserInvestmentAccount,
      { userId: number; investmentAccountId: number }
    >({
      query: ({ userId, investmentAccountId }) => {
        return {
          url: `/users/${userId}/investment-accounts/${investmentAccountId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: 'Investment-Accounts', id: data.investmentAccountId },
      ],
    }),

    resetUserIdentificationDetails: builder.mutation<
      UserIdentification,
      { id?: string | number; status: string }
    >({
      query: ({ id, status }) => ({
        url: `/users/${id || getCookie(USER_ID)}/identification-details/reset?status=${status}`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'Identification-Details', id: 'currentUserRoleAccreditation' }] : [],
    }),
  }),
});

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUserRoleAccreditationQuery,
  useGetUserIdentificationQuery,
  useGetUserInvestmentAccountQuery,
  useSaveUserInvestmentAccountMutation,
  useUpdateRoleAccreditationMutation,
  useUpdateUserInvestmentAccountMutation,
  useUseGetIABeneficialOwnersQuery,
  useDeleteInvestmentAccountMutation,
  useResetUserIdentificationDetailsMutation,
} = userApi;
