import { SVGProps, memo } from 'react';

const SvgSwitchRole24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_142_1875)">
      <path
        d="M11.9997 11.938C13.0593 11.938 13.9183 11.0789 13.9183 10.0193C13.9183 8.95968 13.0593 8.10068 11.9997 8.10068C10.9401 8.10068 10.0811 8.95968 10.0811 10.0193C10.0811 11.0789 10.9401 11.938 11.9997 11.938Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2155 14.9215C14.7954 13.5451 13.4944 12.5132 11.9999 12.5132C10.5054 12.5132 9.20438 13.5451 8.7843 14.9215"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4759 6.52394C16.9203 4.6861 14.5964 3.51932 12 3.51932C7.31621 3.51932 3.51929 7.31624 3.51929 12C3.51929 12.9837 3.68677 13.9283 3.99478 14.8068M5.54181 17.497C7.09735 19.3228 9.41343 20.4807 12 20.4807C16.6837 20.4807 20.4807 16.6837 20.4807 12C20.4807 10.9708 20.2973 9.9845 19.9616 9.07186"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0378 6.77279H18.6951V4.1156"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96213 17.2272H5.30493V19.8844"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_142_1875">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgSwitchRole24Px);
export default Memo;
