import { fileFields } from './common.strings';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  IConfirmRoleAccreditationPayload,
  RoleAccreditationDetails,
} from 'src/interfaces/role-accreditation';
import { setActiveNavigationItem, setUserPaths } from 'src/store/app/reducer';
import { COGNITO_USER_ID } from 'src/constants/registration';
import {
  REGISTRATION_PAGE,
  BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
  IMPERSONATOR_PAGE,
} from 'src/constants/paths';
import { getCookie } from 'cookies-next';
import { getSingleUserRole, userGQLString } from './users/get-user.query';
import { User } from 'src/interfaces/user';

const roleAccreditationGQLString = `
industries {
  id
  nameStringId
}
investmentAmount
investmentFrequency
dueDiligenceParticipation
ventureMentoringParticipation
equityFundingParticipation
ventureCapitalLawyer
cv {
  ${fileFields}
}
linkedInUrl
professionalBackgroundNotes
bic
iban
investments {
  endDate
  investmentAmount
  investmentRoles 
  investmentType
  startDate
  id
  venture {
    registrationCountry
    name
    refId
    locations {
      city
    }
  }
}
previousInvestmentsNotes
investmentHolding {
  name
  registrationCountry
}
ercWalletId
investmentAccountHolder
regulatoryConfirmations
previousTokenizationExperience
tokenizationExperiencePeriod
tokenizationInvestmentAmount
tokenizationInvestmentFrequency
capitalMarketExperience
capitalMarketExperienceType
otherCapitalMarketExperienceType
primaryVentureStatus
primaryVentureStage
investorPortfolio {
  id
  holding {
    registrationCountry
    name
  }
  venture {
    registrationCountry
    name
    id
    refId
    locations {
      city
    }
  }
}
user {
  ${userGQLString}
}
`;

export const userRoleAccreditation = gql`
  query ($userId: Long) {
    userRoleAccreditationDetails(userId: $userId) {
      ${roleAccreditationGQLString}
    }
  }
`;

export const mutationRoleAccreditation = gql`
  mutation saveRoleAccreditationDetails(
    $industries: [IndustryInput]
    $investmentAmount: InvestmentAmount
    $investmentFrequency: InvestmentFrequency
    $dueDiligenceParticipation: VentureProcessesParticipation
    $equityFundingParticipation: VentureProcessesParticipation
    $ventureMentoringParticipation: VentureProcessesParticipation
    $ventureCapitalLawyer: Boolean
    $linkedInUrl: String
    $professionalBackgroundNotes: String
    $bic: String
    $iban: String
    $investments: [UserInvestmentInput]
    $previousInvestmentsNotes: String
    $investmentHolding: CompanyInput
    $ercWalletId: String
    $investmentAccountHolder: String
    $regulatoryConfirmations: [RegulatoryConfirmation]
    $previousTokenizationExperience: UserQuestionAnswer
    $tokenizationExperiencePeriod: TokenizationExperiencePeriod
    $tokenizationInvestmentAmount: TokenizationInvestmentAmount
    $tokenizationInvestmentFrequency: TokenizationInvestmentFrequency
    $capitalMarketExperience: UserQuestionAnswer
    $capitalMarketExperienceType: [CapitalMarketExperienceType]
    $otherCapitalMarketExperienceType: String
    $investorPortfolio: [UserInvestmentPortfolioInput]
    $primaryVentureStatus: UserVentureStatus
    $primaryVentureStage: VentureStage
    $cv: FileInput
  ) {
    saveRoleAccreditationDetails(
      roleAccreditationDetails: {
        industries: $industries
        investmentAmount: $investmentAmount
        investmentFrequency: $investmentFrequency
        dueDiligenceParticipation: $dueDiligenceParticipation
        equityFundingParticipation: $equityFundingParticipation
        ventureMentoringParticipation: $ventureMentoringParticipation
        ventureCapitalLawyer: $ventureCapitalLawyer
        linkedInUrl: $linkedInUrl
        professionalBackgroundNotes: $professionalBackgroundNotes
        bic: $bic
        iban: $iban
        investments: $investments
        previousInvestmentsNotes: $previousInvestmentsNotes
        investmentHolding: $investmentHolding
        ercWalletId: $ercWalletId
        investmentAccountHolder: $investmentAccountHolder
        regulatoryConfirmations: $regulatoryConfirmations
        previousTokenizationExperience: $previousTokenizationExperience
        tokenizationExperiencePeriod: $tokenizationExperiencePeriod
        tokenizationInvestmentAmount: $tokenizationInvestmentAmount
        tokenizationInvestmentFrequency: $tokenizationInvestmentFrequency
        capitalMarketExperience: $capitalMarketExperience
        capitalMarketExperienceType: $capitalMarketExperienceType
        otherCapitalMarketExperienceType: $otherCapitalMarketExperienceType
        investorPortfolio: $investorPortfolio
        primaryVentureStatus: $primaryVentureStatus
        primaryVentureStage: $primaryVentureStage
        cv: $cv
      }
    ) {
      ${roleAccreditationGQLString}
    }
  }
`;

export function useGetRoleAccreditation(params?: QueryHookOptions) {
  const cognitoUserId = getCookie(COGNITO_USER_ID);
  const router = useRouter();
  const dispatch = useDispatch();
  const { data, loading, error, refetch } = useQuery<{
    userRoleAccreditationDetails: RoleAccreditationDetails;
  }>(userRoleAccreditation, {
    skip:
      !cognitoUserId ||
      [BENEFICIAL_OWNER_IDENTIFICATION_PAGE, REGISTRATION_PAGE, IMPERSONATOR_PAGE].includes(
        router.pathname,
      ),
    onError: (e) => {
      if (e.message.includes('is not found')) {
        router.push(REGISTRATION_PAGE);
      }
    },
    onCompleted: ({ userRoleAccreditationDetails }) => {
      dispatch(
        setUserPaths(
          (userRoleAccreditationDetails.user ? userRoleAccreditationDetails.user : {}) as User,
        ),
      );
      dispatch(setActiveNavigationItem(router.pathname));
    },
    ...params,
  });

  const memorized = useMemo(
    () => (!data?.userRoleAccreditationDetails ? null : data.userRoleAccreditationDetails),
    [data],
  );
  const userRole = useMemo(
    () => (!memorized || !memorized.user ? undefined : getSingleUserRole(memorized.user)),
    [memorized],
  );

  return {
    userRoleAccreditationDetails: memorized,
    userRole,
    loading,
    error,
    refetch,
  };
}

export function useGetPublicRoleAccreditation(params?: QueryHookOptions) {
  const cognitoUserId = getCookie(COGNITO_USER_ID);
  const { data, loading, error, refetch } = useQuery<{
    userRoleAccreditationDetails: RoleAccreditationDetails;
  }>(userRoleAccreditation, {
    skip: !cognitoUserId,
    ...params,
  });

  const memorized = useMemo(
    () => (!data?.userRoleAccreditationDetails ? null : data.userRoleAccreditationDetails),
    [data],
  );
  const userRole = useMemo(
    () => (!memorized || !memorized.user ? undefined : getSingleUserRole(memorized.user)),
    [memorized],
  );

  return {
    userRoleAccreditationDetails: memorized,
    userRole,
    loading,
    error,
    refetch,
  };
}

export const useSaveRoleAccreditation = () => {
  const [mutateUserRoleAccreditation, { loading }] = useMutation(mutationRoleAccreditation, {
    refetchQueries: [userRoleAccreditation],
  });

  const saveRoleAccreditation = useCallback(
    async (values: RoleAccreditationDetails) => {
      await mutateUserRoleAccreditation({
        variables: values,
      });
    },
    [mutateUserRoleAccreditation],
  );
  return { saveRoleAccreditation, loading };
};

const confirmUserRoleAccreditation = gql`
  mutation confirmUserRoleAccreditation(
    $roleAccreditationStatus: UserRoleAccreditationStatusInput!
  ) {
    confirmUserRoleAccreditation(roleAccreditationStatus: $roleAccreditationStatus)
  }
`;

export const useConfirmRoleAccreditation = () => {
  const [mutateRoleAccreditation, { loading }] = useMutation(confirmUserRoleAccreditation);

  const saveRoleAccreditationStatus = useCallback(
    async (values: IConfirmRoleAccreditationPayload) => {
      await mutateRoleAccreditation({
        variables: values,
      });
    },
    [mutateRoleAccreditation],
  );
  return { saveRoleAccreditationStatus, loading };
};

export function useGetRoleAccreditationById(id: number | undefined, params?: QueryHookOptions) {
  const { data, loading, error, refetch } = useQuery<{
    userRoleAccreditationDetails: RoleAccreditationDetails;
  }>(userRoleAccreditation, {
    skip: !id,
    variables: { userId: id },
    fetchPolicy: 'no-cache',
    ...params,
  });

  return {
    userRoleAccreditationDetails: data?.userRoleAccreditationDetails || null,
    loading,
    error,
    refetch,
  };
}

export function useGetRoleAccreditationPublic(params?: QueryHookOptions) {
  const cognitoUserId = getCookie(COGNITO_USER_ID);

  const { data, loading, error, refetch } = useQuery<{
    userRoleAccreditationDetails: RoleAccreditationDetails;
  }>(userRoleAccreditation, {
    skip: !cognitoUserId,
    ...params,
  });

  return {
    userRoleAccreditationDetails: data?.userRoleAccreditationDetails || null,
    loading,
    error,
    refetch,
  };
}
