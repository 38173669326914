import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { getCookie } from 'cookies-next';
import { useMemo } from 'react';
import * as Sentry from '@sentry/browser';

import { Roles, User } from 'src/interfaces/user';
import { COGNITO_USER_ID } from 'src/constants/registration';
import { identificationDetailsGQLString } from './user-identification-details.query';
import { fileFields } from '../common.strings';
import { useSelector } from 'react-redux';
import { getStaffImpersonate } from 'src/store/staff-impersonate/reducer';

export const userGQLString = `
id
birthDate
gender
cognitoUserId
firstName
middleName
lastName
fullName
title
phoneNumber
phoneNumberCountryCode
email
image {
  ${fileFields}
}
userVentureRelations {
  id
  role
  ventureId
  userId
  rejectionReason
  verified
}
roleAssignments {
  id
  role
  userId
  verificationNotes
  verificationStatus
}
birthDate
birthPlace
city
country
furtherNationalities
nationality
street
zip
identificationDetails {
  ${identificationDetailsGQLString}
}
shortBio
linkedInUrl
registrationStatus
createdAt
`;

export const userQuery = gql`
  query user($id: Long) {
    user(id: $id) {
      ${userGQLString}
    }
  }
`;

export const userVenturesQuery = gql`
  query {
    user {
      userVentureRelations {
        id
        venture {
          id
          refId
          name
          incorporationDate
          equityOfferings {
            id
          }
        }
      }
    }
  }
`;

export const getSingleUserRole = (u: User) => {
  const isManager = u.userRoles.some((e) => e.role === Roles.INVESTMENT_MANAGER);
  return isManager ? Roles.INVESTMENT_MANAGER : u.userRoles[0]?.role;
};

export function useGetUser(params?: QueryHookOptions) {
  const { data, loading, error, refetch } = useQuery<{ user: User }>(userQuery, {
    skip: true,
    ...params,
  });

  if (data) {
    Sentry.setUser({
      id: data.user.userId.toString(),
      username: data.user.fullName,
    });
  }

  const memorized = useMemo(() => (!data?.user ? null : data.user), [data]);
  const role = useMemo(() => (!memorized ? undefined : getSingleUserRole(memorized)), [memorized]);

  return {
    user: memorized,
    role,
    loading,
    error,
    refetch,
  };
}

export function useReceiveUser(params?: QueryHookOptions) {
  const { user, adminView } = useSelector(getStaffImpersonate);
  const cognitoUserId = getCookie(COGNITO_USER_ID);

  const { data, loading, error, refetch } = useQuery<{ user: User }>(userQuery, {
    skip: !cognitoUserId || adminView,
    ...params,
  });

  const memorized = useMemo(() => {
    if (adminView) return user;
    return !data?.user ? null : data.user;
  }, [adminView, data?.user, user]);
  const role = useMemo(() => (!memorized ? undefined : getSingleUserRole(memorized)), [memorized]);

  return {
    user: memorized,
    role,
    loading,
    error,
    refetch,
  };
}

export const useGetUnLoggedUser = () => {
  const [getUser, { data, loading, error }] = useLazyQuery<{ user: User }>(userQuery, {
    context: {
      headers: {
        ['X-Api-Key']: process.env.X_API_KEY,
      },
    },
  });
  const memorized = useMemo(() => (!data?.user ? null : data.user), [data]);

  return {
    loading,
    error,
    getUser,
    user: memorized,
  };
};
