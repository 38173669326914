import { useCallback, useState, useMemo, useEffect } from 'react';
import { getCookie, setCookies } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'next-i18next';
import { jwtDecode } from 'jwt-decode';

import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { RoleAssignment, Roles, User, UserInvitation } from 'src/interfaces/user';
import { VentureRoleInfoModal } from '../modals/ventureRolesInfoModal';
import { COGNITO_USER_ID } from 'src/constants/registration';
import { HOME_PAGE, PROFILE_COMPLETION_PAGE } from 'src/constants/paths';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { AmplifyAuthRefreshSession } from 'src/interfaces/auth';
import classes from '../index.module.scss';
import { classNames } from 'src/utils/classNames';
import { StepProps } from '../shared';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';
import { CheckBoxSetValue, ServerSharedCheckBox } from 'src/server-shared/inputs/checkbox';
import { useUpdateUserMutation } from 'src/store/user/api';

const roles = [
  {
    id: 1,
    role: Roles.INVESTOR,
    roleString: 'aquaty-app.registration.account-creation.investor.title',
    roleSubtitleString: 'aquaty-app.registration.account-creation.investor.subtitle',
  },
  {
    id: 2,
    role: Roles.INVESTMENT_MANAGER,
    roleString: 'aquaty-app.registration.account-creation.investment-manager.title',
    roleSubtitleString: 'aquaty-app.registration.account-creation.investment-manager.subtitle',
  },
];

export const roleLabel: Record<string, string> = {
  [Roles.VENTURE_FOUNDER]: 'venture_founder',
  [Roles.INVESTOR]: 'venture_investor',
  [Roles.INVESTMENT_MANAGER]: 'investment_manager',
};

export const refreshSession = async (callback?: () => void) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      (_: any, session: AmplifyAuthRefreshSession) => {
        setCookies(COGNITO_JWT, session.accessToken.jwtToken);
        if (callback) callback();
      },
    );
  } catch (e) {
    console.log('error', e);
  }
};

export const SetupVentureRole = ({ showError, user, userInvitationToken }: StepProps) => {
  const { t } = useTranslation();
  const { track, mixpanelLogin } = useMixpanel();
  const router = useRouter();

  const decoded: UserInvitation | null = useMemo(() => {
    if (userInvitationToken) {
      return jwtDecode(userInvitationToken);
    }
    return null;
  }, [userInvitationToken]);

  const userRoles = useMemo(() => {
    if (decoded) {
      return decoded.participationRole ? [decoded.participationRole] : [Roles.INVESTOR];
    }
    return [];
  }, [decoded]);
  const [saveUser, { isLoading: isFetching, data: savedUser }] = useUpdateUserMutation();
  // const { saveUser, loading: isFetching, savedUser } = useSaveUser();
  const [values, setValues] = useState<CheckBoxSetValue>(userRoles);

  const role = useMemo(() => values[0], [values]);

  const [showRoleInfoModal, setShowRoleInfoModal] = useState<boolean>(false);
  const openRoleInfoModal = useCallback(() => setShowRoleInfoModal(true), []);
  const closeRoleInfoModal = useCallback(() => setShowRoleInfoModal(false), []);

  const nextPage = useCallback(
    (savedUser: User) => {
      const isInvestor = savedUser.userRoles.some((el) => el.role === Roles.INVESTOR);
      if (isInvestor) {
        router.push(PROFILE_COMPLETION_PAGE);
        return;
      }
      router.push(HOME_PAGE);
    },
    [router],
  );

  const saveData = async () => {
    await refreshSession();
    if (user?.userId) {
      try {
        await saveUser({
          id: user.userId,
          value: {
            ...user,
            userRoles: values.map((role) => ({ role } as RoleAssignment)),
          },
        });
      } catch (err) {
        showError();
      }
    }
  };

  const isInvestor = useMemo(() => {
    return values.includes(Roles.INVESTOR);
  }, [values]);

  useEffect(() => {
    if (savedUser) {
      const cognitoUserId = getCookie(COGNITO_USER_ID) as string;
      const SignUpWithLinkedIn = cognitoUserId.includes('linkedin_linkedin');

      mixpanelLogin(savedUser.userId.toString());
      track(mixpanelEvents.userRegistration, {
        ['Role']: roleLabel[values[0]],
        ['SignUp Method']: [SignUpWithLinkedIn ? 'LinkedIn' : 'Email'],
        userId: savedUser.userId,
        userEmail: savedUser.email,
      });

      refreshSession(() => nextPage(savedUser));
    }
  }, [mixpanelLogin, nextPage, savedUser, track, values, user, saveUser]);

  return (
    <div className={classNames(classes['step-wrapper'])}>
      <div className="grid-row justify-items-center">
        <div className="grid-row xs justify-items-center">
          <ServerSharedText type="header" className="text-align-center">
            {t('aquaty-app.registration.venture-role.title')}
          </ServerSharedText>

          <div className="grid-row none justify-items-center">
            <ServerSharedText color="grey500" className="text-align-center">
              {t('aquaty-app.registration.venture-role.subtitle')}
            </ServerSharedText>
            <ServerSharedText
              type="label"
              color="primary500"
              className="text-align-center"
              onClick={openRoleInfoModal}
              clickable
            >
              {t('aquaty-app.registration.venture-role.link')}
            </ServerSharedText>
          </div>
        </div>
        <div className="grid-row xs">
          {roles.map((el) => {
            return (
              <ServerSharedCheckBox
                key={el.id}
                onCheck={setValues}
                value={el.role}
                values={values}
                isChecked={values.includes(el.role)}
                disabled={userRoles.includes(el.role)}
                label={
                  <div className="grid-row xxs">
                    <ServerSharedText type="label">{t(el.roleString)}</ServerSharedText>
                    <ServerSharedText type="text-small" color="grey500">
                      {t(el.roleSubtitleString)}
                    </ServerSharedText>
                  </div>
                }
              />
            );
          })}
        </div>

        <ServerSharedButton
          variant="inform"
          label={t(isInvestor ? 'aquaty-generic.cta.continue' : 'aquaty-generic.cta.complete')}
          disabled={!role || isFetching}
          style={{ width: '100%' }}
          onClick={saveData}
        />
      </div>
      <VentureRoleInfoModal show={showRoleInfoModal} closeEvent={closeRoleInfoModal} />
    </div>
  );
};
