import { TFunction } from 'next-i18next';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedSelectProps, Option } from 'src/server-shared/inputs/select/types';

export const formatOptionLabel = (
  t: TFunction,
  o: Option,
  { isResourceString, isDisabled }: Partial<ServerSharedSelectProps>,
) => {
  return (
    <ServerSharedText color={isDisabled ? 'grey500' : 'grey900'}>
      {isResourceString ? t(o.label) : <>{o.data?.labelPreview || o.label}</>}
    </ServerSharedText>
  );
};
