import { VentureBusinessCategory, MarketIntegrationStage, Currency } from 'src/interfaces/common';
import { UserHoldingRole } from 'src/interfaces/profile-completion';
import { VentureStage } from 'src/interfaces/common';
import { Gender, Roles } from 'src/interfaces/user';
import countries from 'public/assets/countries.json';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const VENTURE_PITCH_INDUSTRIES_LIMIT = 5;
export const STORED_PROJECT_VERSION = 's_p_v';

export const countryOptions = [
  { value: 'DE', label: 'aquaty-generic.cta.germany' },
  // { value: 'AUSTRIA', label: 'aquaty-generic.cta.austria' },
  // { id: 1, nameStringId: 'Switzerland' },
];

export const yesNoOptions = [
  { value: true, label: 'aquaty-generic.cta.yes' },
  { value: false, label: 'aquaty-generic.cta.no' },
];

export const currencyOptions = [
  { value: '$', label: 'USD' },
  { value: '€', label: 'EUR' },
];

export const stageOptions = [
  { label: 'aquaty-generic.cta.pre-seed', value: VentureStage.PRE_SEED },
  { label: 'aquaty-generic.cta.seed', value: VentureStage.SEED },
  { label: 'aquaty-generic.cta.series-a', value: VentureStage.SERIES_A },
];

export const businessCategoryOptions = [
  { label: 'B2B', value: VentureBusinessCategory.B2B },
  { label: 'B2C', value: VentureBusinessCategory.B2C },
  { label: 'B2B2C', value: VentureBusinessCategory.B2B2C },
  { label: 'B2G', value: VentureBusinessCategory.BTG },
];

export const marketIntegrationStageOptions = [
  { label: 'aquaty-generic.cta.pre-product', value: MarketIntegrationStage.PRE_PRODUCT },
  { label: 'aquaty-generic.cta.pre-revenue', value: MarketIntegrationStage.PRE_REVENUE },
  { label: 'aquaty-generic.cta.post-revenue', value: MarketIntegrationStage.POST_REVENUE },
];

export const genderOptions = [
  { value: Gender.MALE, label: 'aquaty-generic.form.gender.male' },
  { value: Gender.FEMALE, label: 'aquaty-generic.form.gender.female' },
  { value: Gender.DIVERSE, label: 'aquaty-generic.form.gender.diverse' },
];

export const rolesLabel: Record<Roles, string> = {
  VENTURE_FOUNDER: 'aquaty-generic.cta.founder.title',
  INVESTOR: 'aquaty-generic.cta.investor',
  VENTURE_PARTNER: 'aquaty-generic.cta.venture-partner',
  EXPERT_PARTNER: 'aquaty-generic.cta.expert-partner',
  VENTURE_SCOUT: 'aquaty-generic.cta.scout',
  AQUATY_STAFF: 'aquaty-generic.cta.admin',
  SOFT_REGISTERED_VENTURE_INVESTOR: 'aquaty-generic.cta.soft-registered',
  INVESTMENT_MANAGER: 'aquaty-generic.cta.investment-manager',
};

export const ventureCountryOptions = [
  { value: 'AT', label: 'aquaty-generic.cta.austria' },
  { value: 'BE', label: 'aquaty-generic.cta.belgium' },
  { value: 'FR', label: 'aquaty-generic.cta.france' },
  { value: 'DE', label: 'aquaty-generic.cta.germany' },
  { value: 'CH', label: 'aquaty-generic.cta.switzerland' },
  { value: 'GB', label: 'aquaty-generic.cta.united-kingdom' },
  { value: 'CY', label: 'aquaty-generic.cta.cyprus' },
  { value: 'NL', label: 'aquaty-generic.cta.netherlands' },
];

export const LINKED_IN_PREFIX = 'https://www.linkedin.com/';
export const CRUNCHBASE_PREFIX = 'https://www.crunchbase.com/';
export const PITCHBOOK_PREFIX = 'https://pitchbook.com/';
export const DEALROOM_PREFIX = 'https://dealroom.co/';
export const TWITTER_PREFIX = 'https://twitter.com/';
export const HTTPS = 'https://';

export const holdingRoleOptions = [
  {
    value: UserHoldingRole.SHAREHOLDER,
    label: 'aquaty-generic.form.holding-role.shareholder',
  },
  {
    value: UserHoldingRole.SUPERVISORY_BOARD,
    label: 'aquaty-generic.form.holding-role.supervisory-board',
  },
  {
    value: UserHoldingRole.MANAGING_DIRECTOR,
    label: 'aquaty-generic.form.holding-role.managing-director',
  },
  {
    value: UserHoldingRole.SPOUSE,
    label: 'aquaty-generic.form.holding-role.spouse',
  },
  {
    value: UserHoldingRole.ECONOMICALLY_ENTITLED_PERSON,
    label: 'aquaty-generic.form.holding-role.economically-entitled-person',
  },
  {
    value: UserHoldingRole.AUTHORIZED_REPRESENTATIVE,
    label: 'aquaty-generic.form.holding-role.authorized-representative',
  },
  {
    value: UserHoldingRole.EXECUTIVE_BOARD_MEMBER,
    label: 'aquaty-generic.form.holding-role.executive-board-member',
  },
  {
    value: UserHoldingRole.CHAIRMAN_OF_THE_SUPERVISORY_BOARD,
    label: 'aquaty-generic.form.holding-role.chairman-supervisory-board',
  },
  {
    value: UserHoldingRole.INHERITOR,
    label: 'aquaty-generic.form.holding-role.inheritor',
  },
];

export const monthOptions = [
  {
    label: 'January',
    value: '01',
  },
  {
    label: 'February',
    value: '02',
  },
  {
    label: 'March',
    value: '03',
  },
  {
    label: 'April',
    value: '04',
  },
  {
    label: 'May',
    value: '05',
  },
  {
    label: 'June',
    value: '06',
  },
  {
    label: 'July',
    value: '07',
  },
  {
    label: 'August',
    value: '08',
  },
  {
    label: 'September',
    value: '09',
  },
  {
    label: 'October',
    value: '10',
  },
  {
    label: 'November',
    value: '11',
  },
  {
    label: 'December',
    value: '12',
  },
];

export const holdingRoleLabels = holdingRoleOptions.reduce((acc, curr) => {
  return { ...acc, [curr.value]: curr.label };
}, {} as { [key: string]: string });

export const autocompleteInputCountries = ventureCountryOptions.map((el) => el.value);
export const countriesLabels = ventureCountryOptions.reduce((acc, curr) => {
  return { ...acc, [curr.value]: curr.label };
}, {} as { [key: string]: string });

export const TOTAL = 'Total';

export const YES = 'yes';
export const NO = 'no';

export const fullNoYesOptions = [
  {
    value: NO,
    label: 'aquaty-generic.cta.no',
    groupName: '',
    id: 50,
  },
  {
    value: YES,
    label: 'aquaty-generic.cta.yes',
    groupName: '',
    id: 51,
  },
];

export const fullYesNoOptions = [...fullNoYesOptions].reverse();

export const initialPagination = { page: 0, size: 9 };

export const AQUATY_VENTURES_DEUTSCHLAND_GMBH = 'AQUATY Ventures Deutschland GmbH';

export const ventureCountryLabels: Record<string, string> = {
  DEU: 'Germany',
  FRA: 'France',
  BEL: 'Belgium',
  CHE: 'Switzerland',
};

export enum HeaderButtons {
  RESET = 'RESET',
}

export const fullCurrencyOptions = [
  { value: Currency.EUR, label: 'aquaty-generic.currency.euro.title' },
  { value: Currency.USD, label: 'aquaty-generic.currency.dollar.title' },
  { value: Currency.GBP, label: 'aquaty-generic.currency.pound.title' },
  { value: Currency.CHF, label: 'aquaty-generic.currency.franc.title' },
];

export const allCountryOptions = countries.map((item: Record<string, any>) => {
  return {
    value: item.alpha2.toUpperCase(),
    label: item['en'], // TODO
    data: {
      flag: (item.alpha2 as string).toLowerCase(),
    },
  };
});

export const allCountryObject: Record<string, any> = allCountryOptions.reduce((acc, curr) => {
  return { ...acc, [curr.value]: curr };
}, {});

const timeOptions = [
  { value: '00:00', label: '00:00' },
  { value: '01:00', label: '01:00' },
  { value: '02:00', label: '02:00' },
  { value: '03:00', label: '03:00' },
  { value: '04:00', label: '04:00' },
  { value: '05:00', label: '05:00' },
  { value: '06:00', label: '06:00' },
  { value: '07:00', label: '07:00' },
  { value: '08:00', label: '08:00' },
  { value: '09:00', label: '09:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

export const getTimeOptions = (closingDate: string) => {
  if (closingDate.split('T')[0] !== new Date().toISOString().split('T')[0]) {
    return timeOptions;
  }

  const hours = new Date().getHours();
  const firstNumber = hours < 10 ? '0' : '';
  const index = timeOptions.findIndex((el) => el.value === `${firstNumber}${hours}:00`);
  return timeOptions.map((el, i) => ({ ...el, isDisabled: i <= index }));
};
