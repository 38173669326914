import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

export interface LoaderProps {
  width?: number;
  height?: number;
  inModal?: boolean;
  className?: string;
  isWhite?: boolean;
}

export const Spinner = ({ width = 40, height = 40, className, isWhite = false }: LoaderProps) => {
  return (
    <svg
      className={classNames(classes.container, className)}
      viewBox="0 0 40 40"
      height={height}
      width={width}
    >
      <circle
        className={classNames(classes.track, { [classes.white]: isWhite })}
        cx="20"
        cy="20"
        r="17.5"
        pathLength="100"
        strokeWidth="5px"
        fill="none"
      />
      <circle
        className={classNames(classes.car, { [classes.white]: isWhite })}
        cx="20"
        cy="20"
        r="17.5"
        pathLength="100"
        strokeWidth="5px"
        fill="none"
      />
    </svg>
  );
};

export const ServerSharedSpinner = ({
  inModal = false,
  className,
  width = 64,
  height = 64,
}: LoaderProps) => {
  return (
    <Spinner
      width={width}
      height={height}
      className={classNames(classes.spinner, className, {
        [classes['in-modal']]: inModal,
      })}
    />
  );
};

export const ServerSharedLoader = ({ inModal = false, ...rest }: LoaderProps) => {
  return (
    <div className={inModal ? classes['inside-modal'] : classes['full-page']}>
      <ServerSharedSpinner inModal={inModal} {...rest} />
    </div>
  );
};
