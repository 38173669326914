import { useTranslation } from 'next-i18next';
import { ServerSharedText } from 'src/server-shared/texts/text';

export const ErrorText = ({ error }: { error: string | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <ServerSharedText
      type="text-xs"
      color="alert600"
      style={{ position: 'absolute', top: '100%', left: 0 }}
    >
      {typeof error === 'string' && error.includes('aquaty-') ? t(error) : error}
    </ServerSharedText>
  );
};
