import { i18n } from '../../next-i18next.config';

export const HOME_PAGE = '/home';
export const MENU_PAGE = '/menu';
export const MENU_ADD_HOLDING_PAGE = '/menu/add-holding';
export const MENU_NOTIFICATION_PAGE = '/menu/notifications';
export const DISCOVERY_PAGE = '/discovery';
export const DISCOVERY_ARCHIVE_PAGE = '/discovery/archive';
export const CLUBS_PAGE = '/clubs';
export const CREATE_CLUB_PAGE = '/clubs/create-club';
export const CLUB_DETAIL_PAGE = '/clubs/[clubId]';
export const CLUB_DEALS_PAGE = '/clubs/[clubId]/deals';
export const CLUB_VERIFICATION_PAGE = '/clubs/[clubId]/verification';
export const CLUB_SPV_VERIFICATION_PAGE = '/clubs/[clubId]/spv-verification';
export const PORTFOLIO_PAGE = '/portfolio';
export const PORTFOLIO_DETAIL_PAGE = '/portfolio/[ventureId]';
export const PORTFOLIO_UPDATES_PAGE = '/portfolio/[ventureId]/updates';
export const OFFERINGS_PAGE = '/equity-offerings';
export const DEAL_ROOM_PAGE = '/equity-offerings/[offeringId]';
export const ROLE_ASSIGNMENT_PAGE = '/equity-offerings/[offeringId]/role-assignment';
export const DUE_DILIGENCE_PAGE = '/equity-offerings/[offeringId]/due-diligence';
export const CLOSE_EQUITY_OFFERING_PAGE = '/equity-offerings/[offeringId]/close-equity-offering';
export const SIGN_INVESTMENT_PAGE = '/equity-offerings/[offeringId]/sign-investment';
export const DATA_ROOM_REVIEW_PAGE = '/equity-offerings/[offeringId]/data-room-review';
export const CAPITAL_TRANSFER_PAGE = '/equity-offerings/[offeringId]/capital-transfer';
export const RELEASE_EQUITY_OFFERING_PAGE =
  '/equity-offerings/[offeringId]/release-equity-offering';
export const PAYMENT_CONFIRMATION_PAGE = '/equity-offerings/[offeringId]/payment-confirmation';
export const NOTARIZE_SHAREHOLDERS_CONTRACTS_PAGE =
  '/equity-offerings/[offeringId]/notarize-shareholder-contracts';
export const CONFIRM_FINDING_AMOUNT_PAGE =
  '/equity-offerings/[offeringId]/confirm-minimum-funding-amount-committed';
export const VENTURE_PAGE = '/venture';
export const VENTURE_DETAILS_PAGE = '/venture/[ventureId]';
export const VENTURE_UPDATES_PAGE = '/venture/[ventureId]/updates';
export const VENTURE_DEAL_ROOM_PAGE = '/venture/[ventureId]/equity-offerings/[offeringId]';
export const VENTURE_LEGAL_DOCUMENTS_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/legal-documents';
export const TERM_SHEET_SETUP_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/term-sheet-setup';
export const DATA_ROOM_SETUP_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/data-room-setup';
export const CAP_TABLE_SETUP_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/cap-table-setup';
export const VENTURE_PITCH_SETUP_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/venture-pitch-setup';
export const EQUITY_ACTIONS_PAGE = '/equity-actions';
export const OFFERING_SETUP_PAGE = '/equity-actions/offering-setup';
export const VALUATION_PAGE = '/equity-actions/offering-setup/[id]/valuation';
export const COLLABORATION_PAGE = '/collaboration';
export const COLLABORATION_MESSAGES_PAGE = '/collaboration/messages';
export const COLLABORATION_MEETINGS_PAGE = '/collaboration/meetings';
export const USER_ACCREDITATION_PAGE = '/user-accreditation';
export const DEAL_TEAM_SETUP_PAGE = '/equity-offerings/[offeringId]/deal-team-setup';
export const USER_ACCREDITATION_DETAILS_PAGE = '/user-accreditation/[userId]';
export const ADMIN_INVESTMENT_MANAGER_ACCREDITATION_PAGE =
  '/user-accreditation/[userId]/investment-manager-accreditation';
export const PORTFOLIO_VENTURES_UPDATES_PAGE = '/portfolio/updates';
export const WAIT_LIST_PAGE = '/p/wait-list';
export const WAIT_LIST_STILL_WAITING_PAGE = '/p/wait-list/still-in-the-waitlist';
export const SPV_ISSUANCE_AGREEMENT_SIGNING =
  '/equity-offerings/[offeringId]/spv-issuance-agreement-signing';
export const TERM_SHEET_REVIEW_PAGE = '/equity-offerings/[offeringId]/term-sheet-review';
export const RELEASE_TO_ALL_INVESTORS_PAGE =
  '/equity-offerings/[offeringId]/release-to-all-aquaty-investors';
export const APPROVE_PUBLIC_EQUITY_OFFERING_ACCESS_PAGE =
  '/equity-offerings/[offeringId]/approve-public-equity-offering-access';
export const ADD_OFFLINE_INVESTMENT_PAGE = '/portfolio/add-offline-investment';
export const OFFLINE_PORTFOLIO_PAGE = '/offline-portfolio';
export const OFFLINE_PORTFOLIO_DETAIL_PAGE = '/offline-portfolio/[ventureId]';
export const OFFLINE_PORTFOLIO_UPDATES_PAGE = '/offline-portfolio/[ventureId]/updates';
export const INVESTMENT_MANAGER_MATCH_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/investment-manager-match';
export const REGULATORY_APPROVAL_PAGE = '/equity-offerings/[offeringId]/regulatory-approval';
export const INBOX_PAGE = '/inbox';
export const MENU_PORTFOLIO_TAX_INFORMATION = '/menu/portfolio-tax-information';
export const TAXES_DOCUMENTS_USER_ID_PAGE = '/taxes-documents/[userId]';
export const PORTFOLIO_CAP_TABLE_SETUP = '/offline-portfolio/[ventureId]/cap-table-setup';
export const INVESTMENT_MEMORANDUM_SETUP_PAGE =
  '/equity-offerings/[offeringId]/investment-memorandum-setup';
export const INVESTMENT_OFFERING_SETUP_PAGE =
  '/venture/[ventureId]/equity-offerings/[offeringId]/investment-offering-setup';
export const MANAGER_INVESTMENT_OFFERING_SETUP_PAGE =
  '/equity-offerings/[offeringId]/investment-offering-setup';
export const MENU_PLATFORM_CONFIGURATION_PAGE = '/menu/platform-configuration';
export const MANAGER_CAP_TABLE_SETUP_PAGE = '/equity-offerings/[offeringId]/cap-table-setup';
export const MANAGER_VENTURE_PITCH_SETUP_PAGE =
  '/equity-offerings/[offeringId]/venture-pitch-setup';
export const MANAGER_DATA_ROOM_SETUP_PAGE = '/equity-offerings/[offeringId]/data-room-setup';
export const MANAGER_TERM_SHEET_SETUP_PAGE = '/equity-offerings/[offeringId]/term-sheet-setup';
export const MANAGER_VENTURE_LEGAL_DOCUMENTS_PAGE =
  '/equity-offerings/[offeringId]/legal-documents';
export const INVESTOR_CRM_PAGE = '/investor-crm';
export const ISSUER_SETUP_PAGE = '/equity-offerings/issuer-setup';
export const MENU_WALLET_SETUP_PAGE = '/menu/wallet-setup';
export const OFFERINGS_ARCHIVE_PAGE = '/equity-offerings/archive';
export const OFFERINGS_COMPLETED_PAGE = '/equity-offerings/completed';

export const LINKEDIN_LOGIN_PAGE = '/auth/login';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const SIGN_IN_PAGE = '/auth/sign-in';
export const DEMO_SIGN_IN_PAGE = '/auth/demo-sign-in';
export const SIGN_UP_PAGE = '/auth/sign-up';
export const REGISTRATION_PAGE = '/registration';
export const LETS_IN_TOUCH_PAGE = '/auth/sign-up/lets-in-touch';
export const PROFILE_COMPLETION_PAGE = '/profile-completion';
export const IDENTIFICATION_PAGE = '/profile-completion/identification';
export const INVESTMENT_EXPERIENCE_PAGE = '/profile-completion/investment-experience';
export const BENEFICIAL_OWNER_IDENTIFICATION_PAGE = '/beneficial-owner-identification';
export const CONGRATULATIONS_PAGE = '/congratulations';
export const IMPERSONATOR_PAGE = '/auth/impersonator';
export const ID_NOW_VERIFICATION_PAGE = '/profile-completion/id-now-verification';
export const SUCCESS_IDENTIFICATION_PAGE = '/profile-completion/success-identification';
export const FAIL_IDENTIFICATION_PAGE = '/profile-completion/fail-identification';

export const NOT_AUTHORIZED_PAGES = [
  LINKEDIN_LOGIN_PAGE,
  RESET_PASSWORD_PAGE,
  SIGN_IN_PAGE,
  DEMO_SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  REGISTRATION_PAGE,
  LETS_IN_TOUCH_PAGE,
  PROFILE_COMPLETION_PAGE,
  IDENTIFICATION_PAGE,
  INVESTMENT_EXPERIENCE_PAGE,
  BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
  CONGRATULATIONS_PAGE,
  WAIT_LIST_PAGE,
  IMPERSONATOR_PAGE,
  ID_NOW_VERIFICATION_PAGE,
  SUCCESS_IDENTIFICATION_PAGE,
  FAIL_IDENTIFICATION_PAGE,
]
  .map((path) =>
    i18n.locales.map((locale) => {
      if (locale === 'en') {
        return path;
      }
      return `/${locale}${path}`;
    }),
  )
  .flat();
