import { ServerSharedSpinner } from 'src/server-shared/loader';
import { GeneralModalProps, ServerSharedModal } from 'src/server-shared/modals/modal';
import { ServerSharedText } from 'src/server-shared/texts/text';

export const SwitchRoleModal = ({ show, closeEvent }: GeneralModalProps) => {
  return (
    <ServerSharedModal show={show} closeEvent={closeEvent}>
      <div className="grid-row l text-align-center">
        <ServerSharedSpinner inModal width={40} height={40} className="position-static" />

        <ServerSharedText>
          Switching gears! Hang tight while we transform you into an investor.
        </ServerSharedText>
      </div>
    </ServerSharedModal>
  );
};
