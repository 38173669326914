import * as PAGES from 'src/constants/paths';
import { Roles, User } from 'src/interfaces/user';
import { getSingleUserRole } from 'src/queries/users/get-user.query';
import { AppNavigationConfig, RolesForNavigation, PathItem } from 'src/interfaces/app-navigation';

const commonPages = {
  home: { path: PAGES.HOME_PAGE, breadcrumb: { title: 'aquaty-app.home.title' } },
  menu: { path: PAGES.MENU_PAGE, breadcrumb: { title: 'aquaty-app.menu.title' } },
};

const discoveryPages = {
  discovery: { path: PAGES.DISCOVERY_PAGE, breadcrumb: { title: 'aquaty-app.discovery' } },
  discoveryArchive: {
    path: PAGES.DISCOVERY_ARCHIVE_PAGE,
    breadcrumb: { title: 'aquaty-app.venture-pitch.cta.archive' },
  },
};

const clubsMainPage = {
  path: PAGES.CLUBS_PAGE,
  breadcrumb: { title: 'aquaty-app.clubs.title' },
};

const clubsDetailPage = {
  path: PAGES.CLUB_DETAIL_PAGE,
  breadcrumb: { title: '', isDynamic: true, query: ['clubId'] },
};

const clubsDealsPage = {
  path: PAGES.CLUB_DEALS_PAGE,
  breadcrumb: { title: 'aquaty-app.clubs.club-deals.title' },
};

const menuNotifications = {
  path: PAGES.MENU_NOTIFICATION_PAGE,
  breadcrumb: { title: 'aquaty-generic.menu.notifications.title' },
};

const releaseEquityOffering = {
  path: PAGES.RELEASE_EQUITY_OFFERING_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.administration.release-equity-offering' },
};

const homeArchive = {
  path: PAGES.OFFERINGS_ARCHIVE_PAGE,
  breadcrumb: { title: 'aquaty-app.venture-pitch.cta.archive' },
};

const portfolioPages = {
  portfolio: { path: PAGES.PORTFOLIO_PAGE, breadcrumb: { title: 'aquaty-app.portfolio.title' } },
  portfolioDetail: {
    path: PAGES.PORTFOLIO_DETAIL_PAGE,
    breadcrumb: { title: '', isDynamic: true, query: ['ventureId'] },
    tabs: [
      {
        title: 'aquaty-app.portfolio.details.anchor.investment-details',
        attribute: 'portfolio_investment_details',
      },
      { title: 'aquaty-app.portfolio.details.anchor.updates', attribute: 'portfolio_updates' },
      {
        title: 'aquaty-app.portfolio.details.anchor.legal-documents',
        attribute: 'portfolio_legal_documents',
      },
      {
        title: 'aquaty-app.portfolio.details.anchor.custody-details',
        attribute: 'portfolio_custody_details',
      },
      {
        title: 'aquaty-app.portfolio.details.anchor.previous-equity-offerings',
        attribute: 'portfolio_previous_equity_offerings',
      },
      {
        title: 'aquaty-app.my-ventures.cap-table.anchor.title',
        attribute: 'my_venture_cap_table',
      },
      {
        title: 'aquaty-app.portfolio.details.anchor.investors-via-aquaty',
        attribute: 'portfolio_investors_via_aquaty',
      },
    ],
  },
  portfolioUpdates: {
    path: PAGES.PORTFOLIO_UPDATES_PAGE,
    breadcrumb: { title: 'aquaty-app.venture.updates.title' },
  },
  portfolioVenturesUpdates: {
    path: PAGES.PORTFOLIO_VENTURES_UPDATES_PAGE,
    breadcrumb: { title: 'aquaty-app.venture.updates.title' },
  },
};

const offlinePortfolioPages = {
  offlinePortfolio: {
    path: PAGES.OFFLINE_PORTFOLIO_PAGE,
    breadcrumb: { title: 'aquaty-app.portfolio.title' },
  },
  offlinePortfolioDetail: {
    path: PAGES.OFFLINE_PORTFOLIO_DETAIL_PAGE,
    breadcrumb: { title: '', isDynamic: true, query: ['ventureId'] },
    tabs: [
      {
        title: 'aquaty-app.portfolio.details.anchor.investment-details',
        attribute: 'portfolio_investment_details',
      },
      {
        title: 'aquaty-app.my-ventures.external-links.title',
        attribute: 'portfolio_external_links',
      },
      {
        title: 'aquaty-app.portfolio.details.anchor.updates',
        attribute: 'portfolio_updates',
      },
      {
        title: 'aquaty-app.portfolio.add-offline-investment.equity-history.title',
        attribute: 'portfolio_equity_history',
      },
      {
        title: 'aquaty-app.portfolio.add-offline-investment.your-transactions.title',
        attribute: 'portfolio_your_transactions',
      },
      {
        title: 'aquaty-app.portfolio.details.anchor.legal-documents',
        attribute: 'portfolio_legal_documents',
      },
    ],
  },
  offlinePortfolioUpdates: {
    path: PAGES.OFFLINE_PORTFOLIO_UPDATES_PAGE,
    breadcrumb: { title: 'aquaty-app.venture.updates.title' },
  },
};

const dealRoomPart = {
  equityOfferings: {
    // shadow page (not exists in app yet)
    path: PAGES.OFFERINGS_PAGE,
    breadcrumb: { title: 'aquaty-app.home.title' },
  },
  dealRoom: {
    path: PAGES.DEAL_ROOM_PAGE,
    breadcrumb: { title: '', isDynamic: true, query: ['offeringId'] },
    headerTag: {
      type: 'private',
      show: false,
      text: 'aquaty-app.equity-actions.equity-offering.set-up-equity-offering.status.private.title',
      club: null,
    },
    // tabs: [
    //   {
    //     title: 'aquaty-app.equity-offerings.deal-room.anchor.overview',
    //     attribute: 'deal_room_overview',
    //   },
    //   {
    //     title: 'aquaty-app.equity-offerings.deal-room.anchor.due-diligence',
    //     attribute: 'deal_room_due_diligence',
    //   },
    //   {
    //     title: 'aquaty-app.equity-offerings.deal-room.anchor.existing-commitments',
    //     attribute: 'deal_room_existing_investment_commitments',
    //   },
    //   {
    //     title: 'aquaty-app.equity-offerings.deal-room.anchor.deal-team',
    //     attribute: 'deal_room_deal_team',
    //   },
    //   {
    //     title: 'aquaty-app.equity-offerings.deal-room.anchor.legal-documents',
    //     attribute: 'deal_room_legal_documents',
    //   },
    // ],
  },
};

const dealRoomRoleAssignment = {
  path: PAGES.ROLE_ASSIGNMENT_PAGE,
  breadcrumb: { title: 'aquaty-app.equity-offering.role-assignment.title' },
};

const dataRoomEvaluation = {
  path: PAGES.DUE_DILIGENCE_PAGE,
  breadcrumb: { title: 'aquaty-app.due-diligence' },
};

const spvIssuanceAgreementSigning = {
  path: PAGES.SPV_ISSUANCE_AGREEMENT_SIGNING,
  breadcrumb: {
    title:
      'aquaty-app.equity-actions.equity-offering.state.equity-offering-release.tasks.spv-issuance-signing.title',
  },
};

const confirmMinimumFundingAmountCommitted = {
  path: PAGES.CONFIRM_FINDING_AMOUNT_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.confirm-minimum-funding-amount-committed' },
};

const paymentConfirmation = {
  path: PAGES.PAYMENT_CONFIRMATION_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.administration.investment-commitment-payment' },
};

const capitalTransfer = {
  path: PAGES.CAPITAL_TRANSFER_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.money-transfer.title' },
};

const notarizeShareholderContracts = {
  path: PAGES.NOTARIZE_SHAREHOLDERS_CONTRACTS_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.notarize-shareholder-contracts' },
};

const addOfflineInvestment = {
  path: PAGES.ADD_OFFLINE_INVESTMENT_PAGE,
  breadcrumb: { title: 'aquaty-app.portfolio.add-offline-investment.title' },
};

const inboxPage = {
  path: PAGES.INBOX_PAGE,
  breadcrumb: { title: 'aquaty-app.inbox.title' },
};

const menuPortfolioTaxInformation = {
  path: PAGES.MENU_PORTFOLIO_TAX_INFORMATION,
  breadcrumb: { title: 'aquaty-app.menu.portfolio-tax-information.title' },
};

const portfolioCapTableSetup = {
  path: PAGES.PORTFOLIO_CAP_TABLE_SETUP,
  breadcrumb: { title: 'aquaty-app.equity-actions.equity-offering.legal-venture-data.title' },
};

// app sidebar components start ----------------------------------------
const menuCommonItemForSidebar = {
  path: commonPages.menu.path,
  title: 'aquaty-app.menu.title',
  iconSet: 'menu' as const,
};
// app sidebar components end ----------------------------------------

const roleNavigationConfig = new Map<RolesForNavigation, AppNavigationConfig>([
  [
    Roles.INVESTOR,
    {
      sidebar: [
        {
          path: PAGES.HOME_PAGE,
          iconSet: 'home',
          title: 'aquaty-app.investment-opportunities.title',
          nestedPaths: [
            PAGES.DEAL_ROOM_PAGE,
            PAGES.SIGN_INVESTMENT_PAGE,
            PAGES.DATA_ROOM_REVIEW_PAGE,
            PAGES.OFFERINGS_PAGE,
            PAGES.OFFERINGS_ARCHIVE_PAGE,
          ],
        },
        {
          path: PAGES.PORTFOLIO_PAGE,
          title: 'aquaty-app.portfolio.title',
          iconSet: 'portfolio',
          nestedPaths: [
            PAGES.PORTFOLIO_PAGE,
            PAGES.PORTFOLIO_DETAIL_PAGE,
            PAGES.PORTFOLIO_UPDATES_PAGE,
            PAGES.PORTFOLIO_VENTURES_UPDATES_PAGE,
            PAGES.ADD_OFFLINE_INVESTMENT_PAGE,
            PAGES.OFFLINE_PORTFOLIO_DETAIL_PAGE,
            PAGES.OFFLINE_PORTFOLIO_UPDATES_PAGE,
            PAGES.PORTFOLIO_CAP_TABLE_SETUP,
          ],
        },
        {
          ...menuCommonItemForSidebar,
          nestedPaths: [
            PAGES.MENU_NOTIFICATION_PAGE,
            PAGES.MENU_ADD_HOLDING_PAGE,
            PAGES.MENU_PORTFOLIO_TAX_INFORMATION,
            PAGES.MENU_WALLET_SETUP_PAGE,
          ],
        },
      ],
      paths: {
        collaboration: {
          path: PAGES.COLLABORATION_PAGE,
          breadcrumb: { title: 'aquaty-app.collaboration.title-1' },
        },
        equityOfferings: {
          ...dealRoomPart.equityOfferings,
          breadcrumb: { title: 'aquaty-app.investment-opportunities.title' },
        },
        signInvestment: {
          path: PAGES.SIGN_INVESTMENT_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offerings.investment-committment.title' },
        },
        dataRoomReview: {
          path: PAGES.DATA_ROOM_REVIEW_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offering.data-room-review.title' },
        },
        ...discoveryPages,
        ...portfolioPages,
        ...offlinePortfolioPages,
        dealRoom: dealRoomPart.dealRoom,
        menuAddHolding: {
          path: PAGES.MENU_ADD_HOLDING_PAGE,
          breadcrumb: { title: 'aquaty-app.menu.add-holding.title' },
        },
        addOfflineInvestment,
        menuNotifications,
        clubsMainPage,
        clubsDetailPage,
        clubsDealsPage,
        menu: commonPages.menu,
        home: {
          path: PAGES.HOME_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-opportunities.title' },
        },
        homeArchive,
        inboxPage,
        menuPortfolioTaxInformation,
        portfolioCapTableSetup,
        walletSetup: {
          path: PAGES.MENU_WALLET_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.profile-completion.wallet-setup.title' },
        },
      },
    },
  ],
  [
    Roles.AQUATY_STAFF,
    {
      sidebar: [
        {
          path: PAGES.USER_ACCREDITATION_PAGE,
          iconSet: 'accreditation',
          title: 'aquaty-app.aq-staff.user-accreditation.title',
          nestedPaths: [PAGES.ADMIN_INVESTMENT_MANAGER_ACCREDITATION_PAGE],
        },
        {
          path: PAGES.OFFERINGS_PAGE,
          iconSet: 'offering',
          title: 'aquaty-app.home.equity-offerings',
          nestedPaths: [
            PAGES.RELEASE_EQUITY_OFFERING_PAGE,
            PAGES.DATA_ROOM_REVIEW_PAGE,
            PAGES.PAYMENT_CONFIRMATION_PAGE,
            PAGES.CONFIRM_FINDING_AMOUNT_PAGE,
            PAGES.NOTARIZE_SHAREHOLDERS_CONTRACTS_PAGE,
            PAGES.CAPITAL_TRANSFER_PAGE,
            PAGES.DEAL_ROOM_PAGE,
            PAGES.SPV_ISSUANCE_AGREEMENT_SIGNING,
            PAGES.REGULATORY_APPROVAL_PAGE,
            PAGES.APPROVE_PUBLIC_EQUITY_OFFERING_ACCESS_PAGE,
          ],
        },
        {
          path: PAGES.CLUBS_PAGE,
          iconSet: 'club',
          title: 'aquaty-app.clubs.title',
          nestedPaths: [PAGES.CLUB_VERIFICATION_PAGE, PAGES.CLUB_SPV_VERIFICATION_PAGE],
        },
        {
          ...menuCommonItemForSidebar,
          nestedPaths: [PAGES.MENU_PLATFORM_CONFIGURATION_PAGE],
        },
      ],
      paths: {
        menu: commonPages.menu,
        ...dealRoomPart,
        userAccreditation: {
          path: PAGES.USER_ACCREDITATION_PAGE,
          breadcrumb: { title: 'aquaty-app.aq-staff.user-accreditation.title' },
        },
        equityOfferings: {
          path: PAGES.OFFERINGS_PAGE,
          breadcrumb: { title: 'aquaty-app.home.equity-offerings' },
        },
        equityOfferingsDetails: {
          path: PAGES.DEAL_ROOM_PAGE,
          breadcrumb: { title: '', isDynamic: true, query: ['offeringId'] },
          isNotLink: true,
        },
        dataRoomReview: {
          path: PAGES.DATA_ROOM_REVIEW_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offering.data-room-review.title' },
        },
        capitalTransfer,
        paymentConfirmation,
        notarizeShareholderContracts,
        confirmMinimumFundingAmountCommitted,
        clubsMainPage,
        clubsDetailPage,
        clubVerification: {
          path: PAGES.CLUB_VERIFICATION_PAGE,
          breadcrumb: { title: 'aquaty-app.club-verification.title' },
        },
        clubSPVVerification: {
          path: PAGES.CLUB_SPV_VERIFICATION_PAGE,
          breadcrumb: { title: 'aquaty-app.club-spv-verification.title' },
        },
        userAccreditationDetails: {
          path: PAGES.USER_ACCREDITATION_DETAILS_PAGE,
          breadcrumb: { title: '', isDynamic: true },
        },
        investmentManagerAccreditation: {
          path: PAGES.ADMIN_INVESTMENT_MANAGER_ACCREDITATION_PAGE,
          isNotLink: true,
          breadcrumb: {
            title: 'aquaty-app.profile-completion.investment-manager-accreditation.title',
          },
        },
        spvIssuanceAgreementSigning,
        regulatoryApproval: {
          path: PAGES.REGULATORY_APPROVAL_PAGE,
          breadcrumb: { title: 'aquaty-app.aq-staff.regulatory-approval.title' },
        },
        publicRelease: {
          path: PAGES.APPROVE_PUBLIC_EQUITY_OFFERING_ACCESS_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offering.public-release.title' },
        },
        taxesDocumentsUserId: {
          path: PAGES.TAXES_DOCUMENTS_USER_ID_PAGE,
          breadcrumb: { title: '', isDynamic: true },
          isNotLink: true,
        },
        platformConfiguration: {
          path: PAGES.MENU_PLATFORM_CONFIGURATION_PAGE,
          breadcrumb: { title: 'aquaty-generic.menu.platform-configuration.title' },
        },
      },
    },
  ],
  [
    Roles.INVESTMENT_MANAGER,
    {
      sidebar: [
        {
          path: PAGES.HOME_PAGE,
          iconSet: 'home',
          title: 'aquaty-app.investment-offerings.title',
          nestedPaths: [
            PAGES.DUE_DILIGENCE_PAGE,
            PAGES.DEAL_ROOM_PAGE,
            PAGES.ROLE_ASSIGNMENT_PAGE,
            PAGES.DEAL_TEAM_SETUP_PAGE,
            PAGES.RELEASE_EQUITY_OFFERING_PAGE,
            PAGES.SPV_ISSUANCE_AGREEMENT_SIGNING,
            PAGES.CONFIRM_FINDING_AMOUNT_PAGE,
            PAGES.PAYMENT_CONFIRMATION_PAGE,
            PAGES.CAPITAL_TRANSFER_PAGE,
            PAGES.NOTARIZE_SHAREHOLDERS_CONTRACTS_PAGE,
            PAGES.TERM_SHEET_REVIEW_PAGE,
            PAGES.RELEASE_TO_ALL_INVESTORS_PAGE,
            PAGES.INVESTMENT_MEMORANDUM_SETUP_PAGE,
            PAGES.MANAGER_CAP_TABLE_SETUP_PAGE,
            PAGES.MANAGER_VENTURE_PITCH_SETUP_PAGE,
            PAGES.MANAGER_DATA_ROOM_SETUP_PAGE,
            PAGES.MANAGER_TERM_SHEET_SETUP_PAGE,
            PAGES.MANAGER_INVESTMENT_OFFERING_SETUP_PAGE,
            PAGES.MANAGER_VENTURE_LEGAL_DOCUMENTS_PAGE,
            PAGES.CLOSE_EQUITY_OFFERING_PAGE,
            PAGES.OFFERINGS_PAGE,
            PAGES.ISSUER_SETUP_PAGE,
            PAGES.OFFERINGS_ARCHIVE_PAGE,
            PAGES.OFFERINGS_COMPLETED_PAGE,
          ],
        },
        {
          path: PAGES.PORTFOLIO_PAGE,
          iconSet: 'portfolio',
          title: 'aquaty-app.portfolio.title',
          nestedPaths: [
            PAGES.PORTFOLIO_DETAIL_PAGE,
            PAGES.PORTFOLIO_UPDATES_PAGE,
            PAGES.PORTFOLIO_VENTURES_UPDATES_PAGE,
            PAGES.ADD_OFFLINE_INVESTMENT_PAGE,
            PAGES.OFFLINE_PORTFOLIO_DETAIL_PAGE,
            PAGES.OFFLINE_PORTFOLIO_UPDATES_PAGE,
            PAGES.PORTFOLIO_CAP_TABLE_SETUP,
          ],
        },
        {
          path: PAGES.INVESTOR_CRM_PAGE,
          iconSet: 'club',
          title: 'aquaty-app.investor-crm.title',
          nestedPaths: [],
        },
        {
          ...menuCommonItemForSidebar,
          nestedPaths: [PAGES.MENU_NOTIFICATION_PAGE, PAGES.MENU_PORTFOLIO_TAX_INFORMATION],
        },
      ],
      paths: {
        home: {
          ...commonPages.home,
          breadcrumb: { title: 'aquaty-app.investment-offerings.title' },
        },
        homeArchive,
        homeCompleted: {
          path: PAGES.OFFERINGS_COMPLETED_PAGE,
          breadcrumb: { title: 'aquaty-generic.cta.completed' },
        },
        menu: commonPages.menu,
        discovery: {
          ...discoveryPages.discovery,
          breadcrumb: { title: 'aquaty-app.discovery.deal-flow.title' },
        },
        discoveryArchive: discoveryPages.discoveryArchive,
        equityOfferings: {
          ...dealRoomPart.equityOfferings,
          breadcrumb: { title: 'aquaty-app.investment-offerings.title' },
        },
        issuerSetup: {
          path: PAGES.ISSUER_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.issuer-setup.title' },
        },
        dealRoom: dealRoomPart.dealRoom,
        portfolio: {
          ...portfolioPages.portfolio,
          breadcrumb: { title: 'aquaty-app.managed-portfolio.title' },
        },
        offlinePortfolio: {
          ...offlinePortfolioPages.offlinePortfolio,
          breadcrumb: { title: 'aquaty-app.managed-portfolio.title' },
        },
        portfolioDetail: {
          ...portfolioPages.portfolioDetail,
          tabs: [
            {
              title: 'aquaty-app.portfolio.details.anchor.investment-details',
              attribute: 'portfolio_investment_details',
            },
            {
              title: 'aquaty-app.portfolio.details.anchor.updates',
              attribute: 'portfolio_updates',
            },
            {
              title: 'aquaty-app.portfolio.details.anchor.legal-documents',
              attribute: 'portfolio_legal_documents',
            },
            {
              title: 'aquaty-app.portfolio.details.anchor.carry-participations',
              attribute: 'portfolio_carry_participations',
            },
            {
              title: 'aquaty-app.portfolio.details.anchor.investors-via-aquaty',
              attribute: 'portfolio_investors_via_aquaty',
            },
            {
              title: 'aquaty-app.portfolio.details.anchor.custody-details',
              attribute: 'portfolio_custody_details',
            },
            {
              title: 'aquaty-app.portfolio.details.anchor.previous-equity-offerings',
              attribute: 'portfolio_previous_equity_offerings',
            },
            {
              title: 'aquaty-app.my-ventures.cap-table.anchor.title',
              attribute: 'my_venture_cap_table',
            },
          ],
        },
        offlinePortfolioDetail: {
          ...offlinePortfolioPages.offlinePortfolioDetail,
        },
        portfolioUpdates: portfolioPages.portfolioUpdates,
        offlinePortfolioUpdates: offlinePortfolioPages.offlinePortfolioUpdates,
        portfolioVenturesUpdates: portfolioPages.portfolioVenturesUpdates,
        equity: {
          path: PAGES.EQUITY_ACTIONS_PAGE,
          breadcrumb: { title: 'aquaty-app.venture-partner-actions.title' },
        },
        dataRoomEvaluation,
        dealRoomRoleAssignment,
        menuNotifications,
        investorCRM: {
          path: PAGES.INVESTOR_CRM_PAGE,
          breadcrumb: { title: 'aquaty-app.investor-crm.title' },
        },
        spvIssuanceAgreementSigning,
        releaseEquityOffering,
        confirmMinimumFundingAmountCommitted,
        paymentConfirmation,
        capitalTransfer,
        notarizeShareholderContracts,
        addOfflineInvestment,
        termSheetReview: {
          path: PAGES.TERM_SHEET_REVIEW_PAGE,
          breadcrumb: { title: 'aquaty-app.term-sheet-review.title' },
        },
        inboxPage,
        releaseToAllInvestors: {
          path: PAGES.RELEASE_TO_ALL_INVESTORS_PAGE,
          breadcrumb: { title: 'aquaty-app.release-to-all-aquaty-investors.title' },
        },
        menuPortfolioTaxInformation,
        capTable: {
          path: PAGES.MANAGER_CAP_TABLE_SETUP_PAGE,
          breadcrumb: {
            title: 'aquaty-app.equity-actions.equity-offering.legal-venture-data.title',
          },
        },
        venturePitch: {
          path: PAGES.MANAGER_VENTURE_PITCH_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.venture-pitch' },
        },
        setUpDataRoom: {
          path: PAGES.MANAGER_DATA_ROOM_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-actions.equity-offering.data-room.title' },
        },
        setUpTermSheet: {
          path: PAGES.MANAGER_TERM_SHEET_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.term-sheet-setup' },
        },
        portfolioCapTableSetup,
        investmentOfferingSetup: {
          path: PAGES.MANAGER_INVESTMENT_OFFERING_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-offering-setup.title' },
        },
        investmentMemorandumSetup: {
          path: PAGES.INVESTMENT_MEMORANDUM_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-memorandum-setup.title' },
        },
        legalDocuments: {
          path: PAGES.MANAGER_VENTURE_LEGAL_DOCUMENTS_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offerings.deal-room.provide-documents' },
        },
        dealRoomCloseEquityOffering: {
          path: PAGES.CLOSE_EQUITY_OFFERING_PAGE,
          breadcrumb: { title: 'aquaty-generic.cta.close-equity-offering' },
        },
      },
    },
  ],
]);

export const getPathItemByPathname = (pathname: string): PathItem => {
  let item;
  for (const { paths } of roleNavigationConfig.values()) {
    item = Object.keys(paths)
      .map((el) => paths[el])
      .find((el) => el.path === pathname);

    if (item) break;
  }
  return item as PathItem;
};

export const getConfigByUser = (user: User): AppNavigationConfig => {
  const role = getSingleUserRole(user) as RolesForNavigation;

  return {
    sidebar: roleNavigationConfig.get(role)?.sidebar || [],
    paths: roleNavigationConfig.get(role)?.paths || {},
  };
};

export const getStaffPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.AQUATY_STAFF)?.paths || {};
};

export const getFounderPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.VENTURE_FOUNDER)?.paths || {};
};

export const getInvestorPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.INVESTOR)?.paths || {};
};

export const getInvestmentManagerPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.INVESTMENT_MANAGER)?.paths || {};
};
