import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { RoleAssignment, User } from 'src/interfaces/user';
import { UserVentureRelations } from 'src/interfaces/venture';
import { userQuery } from './users/get-user.query';
import { userGQLString } from './users/get-user.query';
import { FileField } from 'src/interfaces/common';

export const userMutation = gql`
  mutation saveUser(
    $invitationToken: String
    $user: UserInput
  ) {
    saveUser(
      user: $user
      invitationToken: $invitationToken
    ) {
      ${userGQLString}
    }
  }
`;

interface UserForSave
  extends Partial<Omit<User, 'userVentureRelations' | 'id' | 'image' | 'userRoles'>> {
  userVentureRelations?: Partial<UserVentureRelations>[];
  invitationToken?: string;
  image?: FileField | null | { id: number | null | undefined };
  userRoles?: Partial<RoleAssignment>[];
}

export const useSaveUser = () => {
  const [mutateUser, { loading, data }] = useMutation<{ saveUser: User }>(userMutation, {
    refetchQueries: [userQuery],
  });

  const saveUser = useCallback(
    async (values: UserForSave) => {
      const { invitationToken, fullName: _, ...user } = values;
      await mutateUser({
        variables: { user, invitationToken },
      });
    },
    [mutateUser],
  );
  return { saveUser, loading, savedUser: data?.saveUser };
};
