import { useTranslation } from 'next-i18next';
import { ServerSharedFeedbackModal } from 'src/server-shared/modals/feedback';
import { GeneralModalProps } from 'src/server-shared/modals/modal';

interface SwitchToIMRoleModalProps extends GeneralModalProps {
  onConfirm: () => void;
}

export const SwitchToIMRoleModal = ({ show, closeEvent, onConfirm }: SwitchToIMRoleModalProps) => {
  const { t } = useTranslation();
  return (
    <ServerSharedFeedbackModal
      show={show}
      closeEvent={closeEvent}
      type="success"
      title={t('aquaty-generic.menu.modal.switch-to-im-modal.title')}
      subtitle={t('aquaty-generic.menu.modal.switch-to-im-modal.subtitle')}
      footer={{
        onConfirm,
        hasLeftButton: false,
        buttonText: 'aquaty-generic.menu.modal.switch-to-im-modal.button',
      }}
    />
  );
};
