import { useTranslation } from 'next-i18next';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedModal } from 'src/server-shared/modals/modal';

interface IVentureRoleInfoModalProps {
  show: boolean;
  closeEvent: () => void;
}

export const VentureRoleInfoModal = ({ show, closeEvent }: IVentureRoleInfoModalProps) => {
  const { t } = useTranslation();
  return (
    <ServerSharedModal
      show={show}
      closeEvent={closeEvent}
      header={{
        title: t('aquaty-app.registration.account-creation.venture-roles.title'),
      }}
    >
      <div className="grid-row">
        <div className="grid-row none">
          <ServerSharedText type="label">
            {t('aquaty-app.registration.account-creation.investor.title')}
          </ServerSharedText>

          <ServerSharedText color="grey500">
            {t('aquaty-app.registration.account-creation.investor.description')}
          </ServerSharedText>
        </div>

        <div className="grid-row none">
          <ServerSharedText type="label">
            {t('aquaty-generic.cta.investment-manager')}
          </ServerSharedText>

          <ServerSharedText color="grey500" as="pre">
            {t('aquaty-app.registration.account-creation.general.description')}
          </ServerSharedText>
        </div>
      </div>
    </ServerSharedModal>
  );
};
