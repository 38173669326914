import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { EquityOfferingParticipants } from 'src/interfaces/offering';
import { fileFields } from '../../common.strings';
import { useGetUser, userGQLString } from '../../users/get-user.query';
import { useMemo } from 'react';
import { Roles } from 'src/interfaces/user';

export const commonParticipantsString = `
equityOfferingId
id
participantAccreditationDetails {
  linkedInUrl
  cv {
    ${fileFields}
  }
  user {
   ${userGQLString}
    }
  }
participantId
participationRole
carryParticipation
comment
filteredEvaluationSections
acceptanceStatus
`;

export const participants = gql`
  query equityOfferingParticipants($equityOfferingId: Long!, $includeHistoricalData: Boolean) {
    equityOfferingParticipants(equityOfferingId: $equityOfferingId, includeHistoricalData: $includeHistoricalData) {
      ${commonParticipantsString}
    }
  }
`;

export function useGetEquityOfferingParticipants(
  id: number,
  params?: QueryHookOptions,
  includeHistoricalData = false,
) {
  const { user } = useGetUser();
  const { data, loading } = useQuery<{ equityOfferingParticipants: EquityOfferingParticipants[] }>(
    participants,
    {
      variables: { equityOfferingId: id, includeHistoricalData: includeHistoricalData },
      ...params,
    },
  );

  const equityOfferingParticipantsRole = useMemo(() => {
    if (data && user) {
      const participants = (data.equityOfferingParticipants || []).filter(
        (el) => el.participantId === user.userId,
      );
      if (participants.length === 1) {
        return participants[0].participationRole;
      }
      if (participants.some((el) => el.participationRole === Roles.INVESTMENT_MANAGER)) {
        return Roles.INVESTMENT_MANAGER;
      }
      if (participants.some((el) => el.participationRole === Roles.VENTURE_PARTNER)) {
        return Roles.VENTURE_PARTNER;
      }
      if (participants.some((el) => el.participationRole === Roles.EXPERT_PARTNER)) {
        return Roles.EXPERT_PARTNER;
      }
    }
    return Roles.VENTURE_FOUNDER;
  }, [data, user]);

  return {
    equityOfferingParticipants: data?.equityOfferingParticipants || [],
    loading,
    equityOfferingParticipantsRole,
  };
}
