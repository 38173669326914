import { useTranslation } from 'next-i18next';

interface AQStringProps {
  componentId: string;
  variables?: { [key: string]: any };
}

export const AQString = ({ componentId, variables = {} }: AQStringProps) => {
  const { t } = useTranslation();
  return <>{t(componentId, variables)}</>;
};
