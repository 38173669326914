/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import { ManagedPortfolio } from 'src/queries/portfolio/get-managed-portfolio-holdings';
import { UnrealizedPortfolio } from 'src/queries/portfolio/get-unrealized-portfolio-holdings';

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Portfolio'],
  endpoints: (builder) => ({
    getUnrealizedPortfolioHoldings: builder.query<UnrealizedPortfolio, null>({
      query: () => ({
        url: '/unrealizedPortfolioHoldings',
      }),
      providesTags: (result) =>
        result
          ? result.unrealizedPortfolioHoldings.holdings.map(({ ventureId }) => ({
              type: 'Portfolio',
              ventureId,
            }))
          : [],
    }),

    getManagedPortfolioHoldings: builder.query<ManagedPortfolio, null>({
      query: () => ({
        url: '/managedPortfolioHoldings',
      }),
      providesTags: (result) =>
        result
          ? result.managedPortfolioHoldings.holdings.map(({ ventureId }) => ({
              type: 'Portfolio',
              ventureId,
            }))
          : [],
    }),
  }),
});

export const { useGetUnrealizedPortfolioHoldingsQuery, useGetManagedPortfolioHoldingsQuery } =
  portfolioApi;
