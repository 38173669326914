import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './app/reducer';
import founderSharedDataReducer from './founder-shared-data/reducer';
import staffImpersonate from './staff-impersonate/reducer';
import { portfolioApi } from './portfolio/api';
import { userApi } from './user/api';
import { issuerApi } from './issuer/api';
import { companyApi } from './company/api';

const rootReducer = combineReducers({
  app: appReducer,
  founderSharedData: founderSharedDataReducer,
  staffImpersonate: staffImpersonate,
  [portfolioApi.reducerPath]: portfolioApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [issuerApi.reducerPath]: issuerApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
});

export default rootReducer;
