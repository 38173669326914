import { memo, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Home from 'public/svg-components/Home';
import HomeSelected from 'public/svg-components/HomeSelected';
import Clubs24Px from 'public/svg-components/Clubs24Px';
import ClubsSelected24Px from 'public/svg-components/ClubsSelected24Px';
import Menu from 'public/svg-components/Menu';
import MenuSelected from 'public/svg-components/MenuSelected';
import Discovery from 'public/svg-components/Discovery';
import SearchSelected from 'public/svg-components/SearchSelected';
import Portfolio from 'public/svg-components/Portfolio';
import PortfolioSelected from 'public/svg-components/PortfolioSelected';
import Venture24Px from 'public/svg-components/Venture24Px';
import VentureSelected24Px from 'public/svg-components/VentureSelected24Px';
import EquityOffering24Px from 'public/svg-components/EquityOffering24Px';
import EquityOfferingSelected24Px from 'public/svg-components/EquityOfferingSelected24Px';
import UserAccreditation from 'public/svg-components/UserAccreditation';
import UserAccreditationSelected from 'public/svg-components/UserAccreditationSelected';
import Star24Px from 'public/svg-components/Star24Px';
import StarSelected24Px from 'public/svg-components/StarSelected24Px';

import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedTooltip } from 'src/server-shared/tooltip';
import { AppSidebarItem } from 'src/interfaces/app-navigation';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import { CompleteProfileModalWrapper } from 'src/server-shared/modals/complete-profile';

const iconSets: Record<AppSidebarItem['iconSet'], any> = {
  home: {
    selected: <HomeSelected className={classes['fill-style']} />,
    common: <Home className={classes['stroke-style']} />,
  },
  club: {
    selected: <ClubsSelected24Px className={classes['fill-style']} />,
    common: <Clubs24Px className={classes['stroke-style']} />,
  },
  menu: {
    selected: <MenuSelected className={classes['venture-selected']} />,
    common: <Menu className={classes['stroke-style']} />,
  },
  discovery: {
    selected: <SearchSelected className={classes['search-selected']} />,
    common: <Discovery className={classes['stroke-style']} />,
  },
  portfolio: {
    selected: <PortfolioSelected className={classes['path-stroke-style-change']} />,
    common: <Portfolio className={classes['stroke-style']} />,
  },
  venture: {
    selected: <VentureSelected24Px className={classes['stroke-style']} />,
    common: <Venture24Px className={classes['stroke-style']} />,
  },
  offering: {
    selected: <EquityOfferingSelected24Px className={classes['equity-offering-selected']} />,
    common: <EquityOffering24Px className={classes['stroke-style']} />,
  },
  accreditation: {
    selected: <UserAccreditationSelected className={classes['fill-style']} />,
    common: <UserAccreditation className={classes['stroke-style']} />,
  },
  star: {
    selected: <StarSelected24Px className={classes['path-stroke-style-change']} />,
    common: <Star24Px className={classes['stroke-style']} />,
  },
};

export const NavItem = memo(({ title, iconSet, path, nestedPaths, disabled }: AppSidebarItem) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const isSelected = useMemo(
    () => nestedPaths.concat(path).includes(pathname),
    [nestedPaths, path, pathname],
  );

  const Component = isSelected ? iconSets[iconSet].selected : iconSets[iconSet].common;

  return (
    <CompleteProfileModalWrapper disabled={disabled}>
      <Link href={disabled ? '#' : path}>
        <li
          className={classNames(classes['navigation-item__opacity'], {
            [classes['navigation-item__opacity--selected']]: isSelected,
          })}
        >
          <ServerSharedTooltip
            shiftX={0}
            text={
              <ServerSharedText type="label" color="body">
                {t(title)}
              </ServerSharedText>
            }
          >
            <div
              className={classNames(classes['navigation-item'], {
                [classes['navigation-item--selected']]: isSelected,
              })}
            >
              {Component}
            </div>
          </ServerSharedTooltip>
        </li>
      </Link>
    </CompleteProfileModalWrapper>
  );
});
