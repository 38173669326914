// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
// import { BrowserTracing } from '@sentry/tracing';
import pj from 'package.json';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    // new BrowserTracing(),
  ],
  environment: process.env.RUNTIME_ENVIRONMENT,
  ignoreErrors: ['Please ignore that error message - http error.', 'routeChange aborted.'],

  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && process.browser) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('sentry', event.event_id);
    }
    return event;
  },

  // To set a uniform sample rate
  tracesSampleRate: 0,
  enabled: process.env.RUNTIME_ENVIRONMENT === 'prod',
  autoSessionTracking: false,
  release: 'aquaty-web-app@' + pj.version,
});
