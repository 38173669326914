'use client';

import {
  createElement,
  useMemo,
  ReactNode,
  CSSProperties,
  ReactHTML,
  MouseEvent,
  forwardRef,
} from 'react';

import { inter, aeonik, getFont } from 'src/styles/fonts';
import { classNames } from 'src/utils/classNames';
import { palette, PaletteKeys } from 'src/styles/palette';
import { AppFonts } from 'src/interfaces/common';
import classes from './index.module.scss';

export type TextTypes =
  | 'hero'
  | 'header'
  | 'header-m'
  | 'header-s'
  | 'overline-m'
  | 'overline-s'
  | 'label-xl'
  | 'label-l'
  | 'label' // TODO rename to label-m
  | 'label-s'
  | 'label-xs'
  | 'label-xxs'
  | 'text' // TODO rename to text-m
  | 'text-bold'
  | 'text-italic'
  | 'text-small'
  | 'text-xs'
  | 'text-xxs';

interface TypographyType {
  type?: TextTypes;
  color?: PaletteKeys;
  children: ReactNode | string;
  as?: keyof ReactHTML;
  className?: string;
  onClick?: (e: MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  style?: CSSProperties;
  htmlFor?: string;
  ellipsis?: boolean;
  clickable?: boolean;
  font?: AppFonts;
}

export const ServerSharedText = ({
  as = 'span',
  children,
  clickable = false,
  ellipsis = false,
  type = 'text',
  font,
  color,
  style,
  htmlFor,
  onClick,
  className = '',
}: TypographyType) => {
  const styles = useMemo(() => {
    if (color) {
      return {
        color: palette[color],
        ...style,
      };
    }
    return style;
  }, [color, style]);

  if (type === 'header' || type === 'header-m' || type === 'header-s' || type === 'hero') {
    const fontClassName = font ? getFont(font).className : aeonik.className;
    return createElement(
      as,
      {
        style: styles,
        htmlFor,
        onClick,
        className: classNames(
          classes['header-text'],
          fontClassName,
          {
            [classes[`header-text__${type}`]]: true,
            [classes.clickable]: clickable,
            [classes.ellipsis]: ellipsis,
          },
          className,
        ),
      },
      children,
    );
  }

  const fontClassName = font ? getFont(font).className : inter.className;
  return createElement(
    as,
    {
      style: styles,
      htmlFor,
      onClick,
      className: classNames(
        classes.text,
        fontClassName,
        {
          [classes[`text__${type}`]]: true,
          [classes.clickable]: clickable,
          [classes.ellipsis]: ellipsis,
        },
        className,
      ),
    },
    children,
  );
};

interface LineRestrictionsProps extends Omit<TypographyType, 'ellipsis'> {
  lines?: number;
}

export const LineRestrictions = forwardRef<HTMLDivElement, LineRestrictionsProps>(
  ({ lines = 2, children, ...rest }, ref) => {
    return (
      <div className={classes['line-restriction']} style={{ WebkitLineClamp: lines }} ref={ref}>
        <ServerSharedText {...rest}>{children}</ServerSharedText>
      </div>
    );
  },
);
