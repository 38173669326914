import { User } from './user';
import { Currency, ExternalLink, FileField, VentureBusinessCategory } from './common';
import { UserHoldingRole } from './profile-completion';
import { BeneficialOwner } from './beneficialOwner';
import { Industry } from './industries';

export interface IVentureLocation {
  city: string;
  country: string;
  id: number;
  lat: number;
  lng: number;
  locationType: LocationType;
  postalCode: string;
  street: string;
}

type LocationType = ELocationType;

export enum ELocationType {
  HEADQUARTER = 'HEADQUARTER',
  LOCATION = 'LOCATION',
}

type VentureStatus = 'ACTIVE' | 'LIQUIDATION' | 'TERMINATED';

export interface Person {
  firstName: string;
  fullName: string;
  lastName: string;
}

// PLEASE USE AND EXTEND THIS INTERFACE!!! DO NOT CREATE OTHER ONE's
export interface Venture {
  refId: string;
  name: string;
  id: number;
  status: VentureStatus;
  locations: IVentureLocation[];
  incorporationDate?: string;
  officialSubject?: string;
  managingDirectors?: Person[] | null;
  externalLinks: ExternalLink[];
  description: string;
  logo: FileField | null;
  headerImage: FileField | null;
  businessCategory: VentureBusinessCategory;
  totalSharesNumber: number;
  industries: Industry[];
  registrationCountry: string;
  legalForm: string;
  registrationCity: string;
  registrationId: string;
  userVentureRelations?: UserVentureRelations[];
  officiallyRegistered?: boolean;
  legalVentureDocuments: FileField[];
}

export interface SuggestVenture {
  id?: number;
  refId: string;
  name: string;
  locations?: IVentureLocation[];
}

export interface UserVentureRelations {
  id: number;
  role: UserVentureRole;
  userId: number;
  ventureId: number;
  rejectionReason: string;
  verified: boolean | null;
}

export enum UserVentureRole {
  'BASIC_EXPERT_PARTNER' = 'BASIC_EXPERT_PARTNER',
  'DUE_DILIGENCE_PARTNER' = 'DUE_DILIGENCE_PARTNER',
  'EXTENDED_EXPERT_PARTNER' = 'EXTENDED_EXPERT_PARTNER',
  'FOUNDER' = 'FOUNDER',
  'INVESTOR' = 'INVESTOR',
}

export interface CompanyAssociatedNaturalPersonBasic
  extends Pick<User, 'firstName' | 'lastName' | 'email'> {
  beneficialOwnerId?: number | string;
  holdingParticipationRole: UserHoldingRole;
}

export interface VentureCustodyDetails {
  blockchainExplorerUrl: string;
  blockchainName: string;
  contractAddress: string;
  denomination: string;
  investorsNumber: number;
  spvName: string;
  tokenName: string;
  tokenSymbol: string;
  ventureId: number;
  contractId: number;
}

export interface VentureInvestments {
  investorId: number;
  totalInvestedAmount: number;
  totalInvestedShares: number;
}

export enum VentureCapitalisationTriggerType {
  'EQUITY_EVENT' = 'EQUITY_EVENT',
  'EQUITY_OFFERING_RELEASE' = 'EQUITY_OFFERING_RELEASE',
  'TOKENS_SIGNING' = 'TOKENS_SIGNING',
}

export interface VentureCapitalisation {
  capitalisationTriggerType: VentureCapitalisationTriggerType;
  createdAt: string;
  id: number;
  previousEvaluation: number | null;
  primaryCurrency: Currency;
  sharePrice: number;
  tokenizedSharesNumber: number;
  totalSharesNumber: number;
  ventureId: number;
  ventureUpdateId: number;
}

export interface MappedVentureCapitalization {
  [key: number]: VentureCapitalisation;
}

export interface UserInvestmentSpecialPurposeVehicle {
  beneficialOwners: BeneficialOwner[];
  id: number;
  taxId: string;
  ventureId: number;
}

export interface InvestedUserVenture {
  ownedSharesNumber: number;
  ownedSharesPercentage: number;
  venture: Venture;
}

export interface MappedVentures {
  [key: number]: Venture;
}
