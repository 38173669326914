import { useTranslation } from 'next-i18next';
import InformationCircleFill24Px from 'public/svg-components/InformationCircleFill24Px';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';
import classes from 'src/server-shared/modals/feedback/index.module.scss';

export const NotSupportedView = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100vw', height: '100vh' }} className="flex">
      <div className="grid-row justify-items-center" style={{ maxWidth: 480 }}>
        <div
          className={classNames('flex', classes['icon-container'], classes['icon-container--info'])}
          style={{ position: 'initial', transform: 'none' }}
        >
          <InformationCircleFill24Px width={48} height={48} viewBox="0 0 24 24" />
        </div>

        <div className="grid-row xs justify-items-center">
          <ServerSharedText type="label-l">
            {t('aquaty-app.not-supported-view.title')}
          </ServerSharedText>

          <ServerSharedText style={{ textAlign: 'center' }} color="grey800">
            {t('aquaty-app.not-supported-view.subtitle')}
          </ServerSharedText>
        </div>

        <ServerSharedButton
          label={t('aquaty-app.not-supported-view.reload-page')}
          style={{ width: '100%' }}
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};
