import { forwardRef, useCallback, useMemo } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'next-i18next';

import { ServerSharedText } from 'src/server-shared/texts/text';
import { formatOptionLabel } from 'src/server-shared/inputs/select/components/option-label';
import { CommonOption } from 'src/server-shared/inputs/select/components/option';
import { IndicatorSeparator } from 'src/server-shared/inputs/select/components/indicator-separator';
import { DropdownIndicatorChevron16px } from 'src/server-shared/inputs/select/components/dropdown-indicator';
import { selectStyles } from 'src/server-shared/inputs/select/select-inline-styles';
import { ErrorText } from 'src/server-shared/inputs/select/components/error-text';
import { Option, ServerSharedSelectProps, ReactSelectRef } from './types';
import { getValue } from 'src/server-shared/inputs/select/utils';
import { classNames } from 'src/utils/classNames';
import { generateUUID } from 'src/utils/uuid';
import classes from './index.module.scss';

export * from './types';

export const ServerSharedSelect = forwardRef<ReactSelectRef, ServerSharedSelectProps>(
  (props, ref) => {
    const {
      options,
      onChange,
      value,
      label,
      placeholder,
      className,
      components,
      optionalLabel,
      ...others
    } = props; // others object for react-select instance
    const { t } = useTranslation();
    const { error, touched, isLoading, isResourceString, isDisabled } = props;

    // for avoid warnings (which appears always when we have several select components on same page)
    const id = generateUUID();

    const onChangeCallback = useCallback((data) => onChange(data), [onChange]);

    const placeholderElement = useMemo(() => {
      if (placeholder && typeof placeholder === 'string') {
        return t(placeholder).replace(/&amp;#x201D;/g, '"');
      }
      return placeholder;
    }, [placeholder, t]);

    const optionalLabelComponent = useMemo(() => {
      if (!optionalLabel) return null;

      if (typeof optionalLabel === 'string')
        return <ServerSharedText color="grey500">{t(optionalLabel)}</ServerSharedText>;

      return optionalLabel;
    }, [optionalLabel, t]);

    return (
      <div className={classNames(classes.container, className)}>
        {label && (
          <ServerSharedText type="label" as="label">
            {t(label)}
            {optionalLabelComponent}
          </ServerSharedText>
        )}
        <ReactSelect
          ref={ref}
          id={'value-select' + id}
          instanceId={'value-select' + id}
          value={getValue(value, options)}
          placeholder={placeholderElement}
          options={options}
          onChange={onChangeCallback}
          loadingMessage={() => 'Loading Options'}
          isLoading={isLoading}
          noOptionsMessage={() => 'No Options'}
          getOptionValue={(o: Option) => o.value}
          getOptionLabel={(o: Option) => o.label}
          formatOptionLabel={(o: Option) =>
            formatOptionLabel(t, o, { isResourceString, isDisabled })
          }
          styles={selectStyles}
          components={{
            IndicatorSeparator,
            DropdownIndicator: DropdownIndicatorChevron16px,
            Option: CommonOption,
            ...components,
          }}
          {...others}
        />
        {error && touched && <ErrorText error={error} />}
      </div>
    );
  },
);
