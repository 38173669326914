import {
  EInvestmentAmount,
  EInvestmentFrequency,
  EVentureProcessesParticipation,
} from '../ui/auth/signup/interfaces';
import { ETitle } from 'src/interfaces/user';
import { VentureStage } from 'src/interfaces/common';

export const COGNITO_USER_ID = 'cognitoUserId';
export const EMAIL = 'email';
export const VERIFICATION_TOKEN = 'verificationToken';
export const INVESTOR_ROLE_REQUEST = 'investorRoleRequest';
export const USER_ID = 'userId';

export const titleOptions = [
  // { value: ETitle['NONE'], label: 'aquaty-generic.form.title.none' },
  // { value: ETitle['BA'], label: 'aquaty-generic.form.title.bachelor' },
  // { value: ETitle['DIPL'], label: 'aquaty-generic.form.title.diploma' },
  // { value: ETitle['MA'], label: 'aquaty-generic.form.title.master-1' },
  { value: ETitle['DR'], label: 'aquaty-generic.form.title.dr' },
  { value: ETitle['PROF'], label: 'aquaty-generic.form.title.professor' },
  { value: ETitle['PROF_DR'], label: 'aquaty-generic.form.title.professor-dr' },
];

export const investmentAmountList = {
  label: 'aquaty-app.registration.investments.how-much-invest',
  options: [
    {
      value: EInvestmentAmount.LESS_THAN_20K,
      label: 'aquaty-app.registration.investments.how-much-invest.option-1',
      groupName: 'investment-amount-list',
      id: 1,
    },
    {
      value: EInvestmentAmount.FROM_20_TO_100K,
      label: 'aquaty-app.registration.investments.how-much-invest.option-2',
      groupName: 'investment-amount-list',
      id: 2,
    },
    {
      value: EInvestmentAmount.FROM_100_TO_250K,
      label: 'aquaty-app.registration.investments.how-much-invest.option-3',
      groupName: 'investment-amount-list',
      id: 3,
    },
    {
      value: EInvestmentAmount.MORE_THAN_500K,
      label: 'aquaty-app.registration.investments.how-much-invest.option-4',
      groupName: 'investment-amount-list',
      id: 4,
    },
  ],
};

export const investmentFrequencyList = {
  label: 'aquaty-app.registration.investments.how-often.title',
  options: [
    {
      value: EInvestmentFrequency.LESS_THAN_TWO_TIMES_PER_YEAR,
      label: 'aquaty-app.registration.investments.how-often.option-1',
      groupName: 'investment-frequency-list',
      id: 10,
    },
    {
      value: EInvestmentFrequency.FROM_TWO_TO_FOUR_TIMES,
      label: 'aquaty-app.registration.investments.how-often.option-2',
      groupName: 'investment-frequency-list',
      id: 11,
    },
    {
      value: EInvestmentFrequency.FROM_FOUR_TO_EIGHT_TIMES,
      label: 'aquaty-app.registration.investments.how-often.option-3',
      groupName: 'investment-frequency-list',
      id: 12,
    },
    {
      value: EInvestmentFrequency.MORE_THAN_EIGHT_TIMES,
      label: 'aquaty-app.registration.investments.how-often.option-4',
      groupName: 'investment-frequency-list',
      id: 13,
    },
  ],
};

export const labelInvestmentFrequency = {
  [EInvestmentFrequency.LESS_THAN_TWO_TIMES_PER_YEAR]: investmentFrequencyList.options[0].label,
  [EInvestmentFrequency.FROM_TWO_TO_FOUR_TIMES]: investmentFrequencyList.options[1].label,
  [EInvestmentFrequency.FROM_FOUR_TO_EIGHT_TIMES]: investmentFrequencyList.options[2].label,
  [EInvestmentFrequency.MORE_THAN_EIGHT_TIMES]: investmentFrequencyList.options[3].label,
};

export const primaryVentureStatusData = {
  title: 'aquaty-app.registration.venture-status.title',
  subtitle: 'aquaty-app.registration.venture-status.subtitle',
  list: [
    {
      title: 'aquaty-app.registration.venture-status.idea.title',
      subtitle: 'aquaty-app.registration.venture-status.idea.subtitle',
      value: 'IDEA',
    },
    {
      title: 'aquaty-app.registration.venture-status.preparing-for-fundraising.title',
      subtitle: 'aquaty-app.registration.venture-status.preparing-for-fundraising.subtitle',
      value: 'PREPARING_FOR_FUNDRAISING',
    },
    {
      title: 'aquaty-app.registration.venture-status.raising-funding-round.title',
      subtitle: 'aquaty-app.registration.venture-status.raising-funding-round.subtitle',
      value: 'RAISING_FUNDING_ROUND',
    },
  ],
};

export const primaryVentureStageData = {
  title: 'aquaty-app.registration.venture-stage.title',
  subtitle: 'aquaty-app.registration.venture-stage.subtitle',
  list: [
    {
      title: 'aquaty-app.registration.venture-stage.pre-seed.title',
      subtitle: 'aquaty-app.registration.venture-stage.pre-seed.subtitle',
      value: VentureStage.PRE_SEED,
    },
    {
      title: 'aquaty-app.registration.venture-stage.seed.title',
      subtitle: 'aquaty-app.registration.venture-stage.seed.subtitle',
      value: VentureStage.SEED,
    },
    {
      title: 'aquaty-app.registration.venture-stage.series-a.title',
      subtitle: 'aquaty-app.registration.venture-stage.series-a.subtitle',
      value: VentureStage.SERIES_A,
    },
  ],
};

export const expertProfessionData = [
  {
    label: 'aquaty-app.registration.profession.how-many-funding',
    options: [
      {
        value: EVentureProcessesParticipation.LESS_THAN_TWO,
        label: 'aquaty-app.registration.profession.how-many-funding.less-than-2',
        groupName: 'expert-profession-data',
        id: 1,
      },
      {
        value: EVentureProcessesParticipation.FROM_TWO_TO_FIVE,
        label: 'aquaty-app.registration.profession.how-many-funding.2-5',
        groupName: 'expert-profession-data',
        id: 2,
      },
      {
        value: EVentureProcessesParticipation.FROM_FIVE_TO_FIFTEEN,
        label: 'aquaty-app.registration.profession.how-many-funding.5-15',
        groupName: 'expert-profession-data',
        id: 3,
      },
      {
        value: EVentureProcessesParticipation.MORA_THAN_FIFTEEN,
        label: 'aquaty-app.registration.profession.how-many-funding.more-than-15',
        groupName: 'expert-profession-data',
        id: 4,
      },
    ],
  },
  {
    label: 'aquaty-app.registration.profession.venture-capital-lawyer',
    options: [
      {
        value: 'true',
        label: 'aquaty-generic.cta.yes',
        groupName: 'expert-profession-data-venture-capital-lawyer',
        id: 50,
      },
      {
        value: 'false',
        label: 'aquaty-generic.cta.no',
        groupName: 'expert-profession-data-venture-capital-lawyer',
        id: 51,
      },
    ],
  },
];

export const labelProfessions = {
  LESS_THAN_TWO: 'aquaty-app.registration.profession.how-many-funding.less-than-2',
  FROM_TWO_TO_FIVE: 'aquaty-app.registration.profession.how-many-funding.2-5',
  FROM_FIVE_TO_FIFTEEN: 'aquaty-app.registration.profession.how-many-funding.5-15',
  MORA_THAN_FIFTEEN: 'aquaty-app.registration.profession.how-many-funding.more-than-15',
};

const generalProfessionOptions = [
  {
    value: EVentureProcessesParticipation.LESS_THAN_TWO,
    label: 'aquaty-app.registration.profession.how-many-funding.less-than-2',
  },
  {
    value: EVentureProcessesParticipation.FROM_TWO_TO_FIVE,
    label: 'aquaty-app.registration.profession.how-many-funding.2-5',
  },
  {
    value: EVentureProcessesParticipation.FROM_FIVE_TO_FIFTEEN,
    label: 'aquaty-app.registration.profession.how-many-funding.5-15',
  },
  {
    value: EVentureProcessesParticipation.MORA_THAN_FIFTEEN,
    label: 'aquaty-app.registration.profession.how-many-funding.more-than-15',
  },
];

export const generalProfessionData = [
  {
    label: 'aquaty-app.registration.profession.how-many-funding',
    options: generalProfessionOptions.map((e, i) => ({
      ...e,
      groupName: 'how-many-funding-group',
      id: i + 1 * 10,
    })),
  },
  {
    label: 'aquaty-app.registration.profession.how-many-ventures',
    options: generalProfessionOptions.map((e, i) => ({
      ...e,
      groupName: 'how-many-ventures-group',
      id: i + 1 * 20,
    })),
  },
  {
    label: 'aquaty-app.registration.profession.how-many-equity-fundings',
    options: generalProfessionOptions.map((e, i) => ({
      ...e,
      groupName: 'how-many-equity-funding-group',
      id: i + 1 * 30,
    })),
  },
];

export const ventureHubsOptions = {
  list1: [
    { id: 1, nameStringId: 'Yes' },
    { id: 0, nameStringId: 'No' },
  ],
  list2: [
    { id: 2, nameStringId: 'Venture Hub A' },
    { id: 3, nameStringId: 'Venture Hub B' },
    { id: 4, nameStringId: 'Venture Hub C' },
    { id: 5, nameStringId: 'Venture Hub E' },
  ],
};

export const generalIndustries = {
  title: 'aquaty-app.registration.industry-experience.title',
  subtitle: 'aquaty-app.registration.venture-financings.subtitle',
  question: 'aquaty-app.registration.venture-financings.which-industries',
  stepIndicator: 'GENERAL_INDUSTRIES',
};

export const expertIndustries = {
  title: 'aquaty-app.registration.industries.title',
  subtitle: 'aquaty-app.registration.industries.subtitle',
  question: 'aquaty-app.registration.venture-financings.expert-which-industries',
  stepIndicator: 'EXPERT_INDUSTRIES',
};

export const countries = {
  DE: 'Germany',
};

export enum RegistrationSteps {
  'PASS_CODE' = 'PASS_CODE',
  'PERSONAL_DATA' = 'PERSONAL_DATA',
  'ROLE' = 'ROLE',
}

export const registrationSteps = [
  {
    value: RegistrationSteps.PASS_CODE,
    label: 'aquaty-app.registration.pass-code.title',
    active: true,
    available: true,
    complete: false,
  },
  {
    value: RegistrationSteps.PERSONAL_DATA,
    label: 'aquaty-generic.menu.personal-data.title',
    active: false,
    available: false,
    complete: false,
  },
  {
    value: RegistrationSteps.ROLE,
    label: 'aquaty-generic.form.role',
    active: false,
    available: false,
    complete: false,
  },
];

export const titleLabels = titleOptions.reduce((acc: any, curr: any) => {
  return {
    ...acc,
    [curr.value]: curr.label,
  };
}, {} as Record<ETitle, string>);
