import { SVGProps, memo } from 'react';

const SvgAddCircle24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_26739_76547)">
      <path
        d="M17.3242 19.9683C15.7482 21.0213 13.8954 21.5833 12 21.5833C9.45834 21.5833 7.02078 20.5737 5.22356 18.7764C3.42634 16.9792 2.41667 14.5417 2.41667 12C2.41667 10.1046 2.97872 8.25176 4.03175 6.67579C5.08478 5.09982 6.58149 3.8715 8.33262 3.14616C10.0837 2.42082 12.0106 2.23104 13.8696 2.60081C15.7286 2.97059 17.4362 3.88331 18.7764 5.22356C20.1167 6.56382 21.0294 8.2714 21.3992 10.1304C21.769 11.9894 21.5792 13.9163 20.8538 15.6674C20.1285 17.4185 18.9002 18.9152 17.3242 19.9683ZM10.75 10.75H7C6.66848 10.75 6.35054 10.8817 6.11612 11.1161C5.8817 11.3505 5.75 11.6685 5.75 12C5.75 12.3315 5.8817 12.6495 6.11612 12.8839C6.35054 13.1183 6.66848 13.25 7 13.25H10.75V17C10.75 17.3315 10.8817 17.6495 11.1161 17.8839C11.3505 18.1183 11.6685 18.25 12 18.25C12.3315 18.25 12.6495 18.1183 12.8839 17.8839C13.1183 17.6495 13.25 17.3315 13.25 17V13.25H17C17.3315 13.25 17.6495 13.1183 17.8839 12.8839C18.1183 12.6495 18.25 12.3315 18.25 12C18.25 11.6685 18.1183 11.3505 17.8839 11.1161C17.6495 10.8817 17.3315 10.75 17 10.75H13.25V7C13.25 6.66848 13.1183 6.35054 12.8839 6.11612C12.6495 5.8817 12.3315 5.75 12 5.75C11.6685 5.75 11.3505 5.8817 11.1161 6.11612C10.8817 6.35054 10.75 6.66848 10.75 7V10.75Z"
        fill="#2180CF"
        stroke="#2180CF"
        strokeWidth="0.833333"
      />
    </g>
    <defs>
      <clipPath id="clip0_26739_76547">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgAddCircle24Px);
export default Memo;
