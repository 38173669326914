import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { ServerSharedText } from 'src/server-shared/texts/text';
import classes from './index.module.scss';
import { Roles, User } from 'src/interfaces/user';
import CheckCirclePrimary24Px from 'public/svg-components/CheckCirclePrimary24Px';
import AddCircle24Px from 'public/svg-components/AddCircle24Px';
import { useSaveUser } from 'src/queries/registration.query';
import { setCookies } from 'cookies-next';
import { INVESTOR_ROLE_REQUEST } from 'src/constants/registration';
import { PROFILE_COMPLETION_PAGE } from 'src/constants/paths';
import { useToggle } from 'src/hooks/useToggle';
import { SwitchToIMRoleModal } from 'src/ui/menu/modals/SwitchToIMRole';
import { SwitchRoleModal } from 'src/ui/menu/modals/SwitchRoleModal';

interface SwitchRoleMenuProps {
  role?: Roles;
  user: User | null;
  onHide: () => void;
}

const roles = [
  {
    role: Roles.INVESTOR,
    title: 'aquaty-app.admin.filter.investor',
    addRole: 'Add Investor Role',
  },
  {
    role: Roles.INVESTMENT_MANAGER,
    title: 'aquaty-app.admin.filter.investment-manager',
    addRole: 'Add Investment Manager Role',
  },
];

export const SwitchRoleMenu = ({ role, user, onHide }: SwitchRoleMenuProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { saveUser, loading: saveUserLoading } = useSaveUser();

  const [showSwitchToIMModal, toggleSwitchToIMModal] = useToggle();
  const [showSwitchRoleModal, toggleSwitchRoleModal] = useToggle();

  const switchRoleHandler = useCallback(
    async (hasRole: boolean, role: Roles, isActive: boolean) => {
      if (isActive && saveUserLoading) {
        return;
      }
      // await saveUser({
      //   ...user,
      //   roleAssignments: [
      //     ...(user?.roleAssignments || []).filter((el) => el.role === Roles.INVESTOR),
      //   ],
      // });
      if (!hasRole) {
        if (role === Roles.INVESTMENT_MANAGER) {
          // await saveUser({
          //   ...user,
          //   roleAssignments: [...(user?.userRoles || []), { role }],
          // });
          toggleSwitchToIMModal();
          return;
        }
        await saveUser({
          ...user,
          userRoles: [...(user?.userRoles || []), { role }],
        });
        setCookies(INVESTOR_ROLE_REQUEST, true);
        push(PROFILE_COMPLETION_PAGE);
      }
      // TODO add redirect/switch role logic
    },
    [push, saveUser, saveUserLoading, toggleSwitchToIMModal, user],
  );

  const switchRole = () => {
    toggleSwitchRoleModal();
  };

  if (!user) {
    return null;
  }

  return (
    <div className={classes['switch-role']} onPointerLeave={onHide}>
      <div className={classes['switch-role__menu']}>
        {roles.map((el) => {
          const hasRole = user.userRoles.some((roleAssignment) => roleAssignment.role === el.role);
          const isActive = role === el.role;
          return (
            <div
              key={el.role}
              onClick={() => switchRoleHandler(hasRole, el.role, isActive)}
              className={classes['switch-role__item']}
            >
              <ServerSharedText color="body">{t(hasRole ? el.title : el.addRole)}</ServerSharedText>
              {isActive && <CheckCirclePrimary24Px />}
              {!hasRole && <AddCircle24Px className={classes['add-icon']} />}
            </div>
          );
        })}
      </div>
      {showSwitchToIMModal && (
        <SwitchToIMRoleModal show closeEvent={toggleSwitchToIMModal} onConfirm={switchRole} />
      )}

      {showSwitchRoleModal && <SwitchRoleModal show closeEvent={toggleSwitchRoleModal} />}
    </div>
  );
};
