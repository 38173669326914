import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Current24Px from 'public/svg-components/Current24Px';
import ToDo24Px from 'public/svg-components/ToDo24Px';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import TimeClockCircleM24Px from 'public/svg-components/TimeClockCircleM24Px';
import AlertCircle24Px from 'public/svg-components/AlertCircle24Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { HOME_PAGE, IDENTIFICATION_PAGE, INVESTMENT_EXPERIENCE_PAGE } from 'src/constants/paths';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ERoleAccreditationStatus,
  Roles,
  User,
  UserIdentification,
  UserIdentificationStatus,
  UserInvestmentAccountStatus,
  UserInvestmentAccountType,
} from 'src/interfaces/user';
import { ServerSharedLoader } from 'src/server-shared/loader';
import { UserInvestmentAccount, WalletVerificationStatus } from 'src/interfaces/profile-completion';
import {
  InvestorIdentificationStepCardProps,
  ProfileCompetitionContentProps,
  StepCardProps,
} from '../../types';
import { fiveSeconds } from '../../shared';
import { RegistrationStep } from '../../components/step';
import { IdentificationButtons } from '../../components/identification-buttons';
import { RoleAccreditationDetails } from 'src/interfaces/role-accreditation';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';
import { refreshSession } from 'src/ui/auth/signup/nested-pages/registration/steps/venture-role';
import { useGetUserInvestmentAccountQuery } from 'src/store/user/api';

export const identificationIsPassed = (identificationDetails?: UserIdentification) =>
  identificationDetails?.verificationStatus === UserIdentificationStatus.APPROVED;
export const accreditationIsPassed = (user: User) => {
  return user.userRoles
    .filter((el) => el.role !== Roles.INVESTMENT_MANAGER)
    .some((el) => el.verificationStatus === ERoleAccreditationStatus.ACCEPTED);
};

export const isPartnersInvestorPersonalDataIsFilled = (user: User): boolean => {
  const isPersonalDataIsFilled = [user.firstName, user.lastName].every(Boolean);

  return isPersonalDataIsFilled;
};

export const hasVerifiedWallet = (userInvestmentAccounts: UserInvestmentAccount[]) =>
  userInvestmentAccounts.some(
    (el) => el.walletDetails?.status === WalletVerificationStatus.VERIFIED,
  );

type InvestorRegistrationStage = 'PERSONAL_DATA' | 'IDENTIFICATION' | 'INVESTMENT_EXPERIENCE';

const identificationIcons = {
  TODO: <ToDo24Px width={16} height={16} viewBox="0 0 24 24" />,
  INPROGRESS: <Current24Px width={16} height={16} viewBox="0 0 24 24" />,
  COMPLETED: <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
  WAITING: <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
  FAILED: <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />,
};

const holdingStatusIcons = {
  [UserInvestmentAccountStatus.PENDING]: (
    <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
  [UserInvestmentAccountStatus.APPROVED]: (
    <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
  [UserInvestmentAccountStatus.REJECTED]: (
    <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
  [UserInvestmentAccountStatus.NOT_STARTED]: (
    <ToDo24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
};

interface InvestorRegistrationStepsProps {
  user: User;
  refetch: () => void;
  userRoleAccreditationDetails: RoleAccreditationDetails;
  identificationDetails?: UserIdentification;
}

const RegistrationSteps = ({
  user,
  refetch,
  userRoleAccreditationDetails,
  identificationDetails,
}: InvestorRegistrationStepsProps) => {
  const { t } = useTranslation();
  const { track } = useMixpanel();
  const { push } = useRouter();

  const [showRetryButton, setShowRetryButton] = useState(false);

  const { data: userInvestmentAccounts = [] } = useGetUserInvestmentAccountQuery(undefined);
  const initialRefetchRef = useRef(false);

  const holdings = useMemo(() => {
    if (!userInvestmentAccounts.length) {
      return [];
    }
    return userInvestmentAccounts.filter((el) => el.type === UserInvestmentAccountType.SPV);
  }, [userInvestmentAccounts]);

  const goToInvestmentExperience = useCallback(() => {
    push(INVESTMENT_EXPERIENCE_PAGE);
  }, [push]);

  const activeStage = useMemo<InvestorRegistrationStage>(() => {
    const isIdentificationIsFinished =
      identificationDetails?.verificationStatus === UserIdentificationStatus.APPROVED;
    if (!isIdentificationIsFinished) return 'IDENTIFICATION';

    const isRoleAccreditationIsFinished = accreditationIsPassed(user);
    if (!isRoleAccreditationIsFinished) return 'INVESTMENT_EXPERIENCE';
    return 'INVESTMENT_EXPERIENCE';
  }, [identificationDetails?.verificationStatus, user]);

  const personalDataStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'PERSONAL_DATA') {
      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.investor-profile.subtitle',
      };
    }
    return { status: 'COMPLETED', showSubtitle: false };
  }, [activeStage]);

  const identificationStep = useMemo<InvestorIdentificationStepCardProps>(() => {
    if (activeStage === 'INVESTMENT_EXPERIENCE') {
      const showWaiting = holdings.some(
        (el) => el.legalEntity.verificationStatus !== UserInvestmentAccountStatus.APPROVED,
      );
      return {
        status: showWaiting ? 'WAITING' : 'COMPLETED',
        personalStatus: 'COMPLETED',
        showSubtitle: !!showWaiting,
        subtitle: t(
          'aquaty-app.profile-completion.investment-accounting-identification.success.subtitle',
          { email: user.email || '' },
        ),
      };
    }
    if (activeStage === 'IDENTIFICATION') {
      if (identificationDetails?.verificationStatus === UserIdentificationStatus.FAILED) {
        return {
          status: 'FAILED',
          personalStatus: 'FAILED',
          subtitle: t(
            'aquaty-app.profile-completion.minimized.investor.identification.failed.subtitle',
          ),
        };
      }

      if (identificationDetails?.verificationStatus === UserIdentificationStatus.PENDING) {
        return {
          status: 'WAITING',
          personalStatus: 'WAITING',
          subtitle: t(
            'aquaty-app.profile-completion.investment-accounting-identification.pending.subtitle',
            { email: user.email || '' },
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        personalStatus: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.investor-identification.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      personalStatus: 'TODO',
      showSubtitle: false,
      subtitle:
        'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
    };
  }, [activeStage, holdings, t, user.email, identificationDetails?.verificationStatus]);

  const investorAccreditation = useMemo(() => {
    return user.userRoles[0];
  }, [user]);

  const regulatoryQuestionsStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'INVESTMENT_EXPERIENCE') {
      if (investorAccreditation.verificationStatus === ERoleAccreditationStatus.REFUSED) {
        return {
          status: 'FAILED',
          subtitle: (
            <ServerSharedText color="grey500" type="text-small">
              {investorAccreditation.verificationComment}
            </ServerSharedText>
          ),
        };
      }

      if (investorAccreditation.verificationStatus === ERoleAccreditationStatus.WAITING) {
        return {
          status: 'WAITING',
          subtitle: t('aquaty-app.profile-completion.investor-accreditation.waiting.subtitle', {
            email: user.email || '',
          }),
        };
      }

      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.regulatory-questions.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      subtitle: 'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
      showSubtitle: false,
    };
  }, [activeStage, investorAccreditation, user.email, t]);

  useEffect(() => {
    let userInterval: any = undefined;

    if (identificationDetails?.verificationStatus === UserIdentificationStatus.PENDING) {
      setShowRetryButton(true);
      if (!initialRefetchRef.current) {
        initialRefetchRef.current = true;
      }

      userInterval = setInterval(() => refetch(), fiveSeconds);
    } else {
      clearInterval(userInterval);
    }

    return () => {
      clearInterval(userInterval);
    };
  }, [user, refetch, showRetryButton, identificationDetails?.verificationStatus]);

  useEffect(() => {
    if (
      isPartnersInvestorPersonalDataIsFilled(user) &&
      identificationIsPassed(identificationDetails) &&
      accreditationIsPassed(user)
      // hasVerifiedWallet(userInvestmentAccounts)
    ) {
      track(mixpanelEvents.fullProfileCompletion, {
        ['Role']: 'venture_investor',
      });
      refreshSession();
      push(HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRoleAccreditationDetails, userInvestmentAccounts, push]);

  return (
    <div className="grid-row m">
      <RegistrationStep
        title="aquaty-generic.cta.profile"
        titleTime="aquaty-app.profile-completion.1-2-minutes"
        {...personalDataStep}
        index={1}
        button={null}
      />

      <RegistrationStep
        title="aquaty-app.profile-completion.founder.identification.title"
        titleTime="aquaty-app.profile-completion.5-10-minutes"
        {...identificationStep}
        index={2}
        button={
          activeStage === 'IDENTIFICATION' ? (
            <>
              <IdentificationButtons
                path={IDENTIFICATION_PAGE}
                showRetryButton={showRetryButton}
                status={identificationDetails?.verificationStatus}
                buttonLabel="aquaty-app.profile-completion.start-identification"
              />
            </>
          ) : null
        }
      >
        {holdings.length > 0 ? (
          <div className="grid-row xs" style={{ marginTop: '4px' }}>
            <div className="grid-column template-mc-1fr xs">
              {identificationIcons[identificationStep.personalStatus]}
              <ServerSharedText color="grey500" type="text-small">
                {t('aquaty-app.profile-completion.investment-accounting-identification.personal')}
              </ServerSharedText>
            </div>

            {holdings.map((el) => {
              return (
                <div className="grid-column template-mc-1fr xs" key={el.investmentAccountId}>
                  {
                    holdingStatusIcons[
                      el.legalEntity.verificationStatus || UserInvestmentAccountStatus.PENDING
                    ]
                  }
                  <ServerSharedText color="grey500" type="text-small">
                    {el.legalEntity.company.name}
                    {el.legalEntity.verificationStatus === UserInvestmentAccountStatus.REJECTED &&
                      t(
                        'aquaty-app.profile-completion.investment-accounting-identification.spv-failed',
                      )}
                  </ServerSharedText>
                </div>
              );
            })}
          </div>
        ) : null}
      </RegistrationStep>

      <RegistrationStep
        title="aquaty-app.profile-completion.investment-experience.title"
        titleTime="aquaty-app.profile-completion.1-2-minutes"
        {...regulatoryQuestionsStep}
        index={3}
        button={
          activeStage === 'INVESTMENT_EXPERIENCE' ? (
            <>
              {(!investorAccreditation.verificationStatus ||
                investorAccreditation.verificationStatus ===
                  ERoleAccreditationStatus.NOT_STARTED) && (
                <ServerSharedButton
                  onClick={goToInvestmentExperience}
                  label={t('aquaty-generic.cta.start-investment-experience')}
                />
              )}
            </>
          ) : null
        }
      />
    </div>
  );
};

export const MinimizedInvestorRegistration = ({
  user,
  refetch,
  investorRoleRequest,
  identificationDetails,
  userRoleAccreditationDetails,
}: ProfileCompetitionContentProps) => {
  const { t } = useTranslation();

  if (!user || !userRoleAccreditationDetails) {
    return <ServerSharedLoader />;
  }

  return (
    <div className="grid-row">
      <div className="grid-row xs">
        <ServerSharedText type="header">
          {t(
            investorRoleRequest
              ? 'aquaty-generic.cta.investor-role-request'
              : 'aquaty-generic.cta.complete-registration',
          )}
        </ServerSharedText>

        <ServerSharedText color="grey500" as="pre">
          {t('aquaty-app.profile-completion.investor.minimized.subtitle')}
        </ServerSharedText>
      </div>

      <RegistrationSteps
        user={user}
        refetch={refetch}
        userRoleAccreditationDetails={userRoleAccreditationDetails}
        identificationDetails={identificationDetails}
      />
    </div>
  );
};
