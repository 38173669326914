import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import Current24Px from 'public/svg-components/Current24Px';
import ToDo24Px from 'public/svg-components/ToDo24Px';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import TimeClockCircleM24Px from 'public/svg-components/TimeClockCircleM24Px';
import AlertCircle24Px from 'public/svg-components/AlertCircle24Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';

export const icons = {
  TODO: <ToDo24Px />,
  INPROGRESS: <Current24Px />,
  COMPLETED: <CheckCircleM24Px />,
  WAITING: <TimeClockCircleM24Px />,
  FAILED: <AlertCircle24Px />,
};

export type RegistrationStepCardStatus = 'TODO' | 'INPROGRESS' | 'COMPLETED' | 'WAITING' | 'FAILED';

interface RegistrationStepProps {
  status: RegistrationStepCardStatus;
  showSubtitle?: boolean;
  title: string;
  subtitle?: string | JSX.Element;
  children?: JSX.Element | null;
  button?: JSX.Element | null;
  index: number;
  titleTime?: string;
}

const getTitleColor = (status: RegistrationStepCardStatus) => {
  switch (status) {
    case 'INPROGRESS':
      return 'primary600';
    case 'FAILED':
    case 'WAITING':
      return 'grey900';
    default:
      return 'grey500';
  }
};

export const RegistrationStep = ({
  title,
  subtitle,
  status,
  showSubtitle = true,
  children = null,
  button = null,
  index,
  titleTime,
}: RegistrationStepProps) => {
  const { t } = useTranslation();
  const Icon = icons[status];
  const showIcon = useMemo(() => ['COMPLETED', 'WAITING', 'FAILED'].includes(status), [status]);
  const active = useMemo(() => status === 'INPROGRESS', [status]);

  return (
    <div className="grid-column template-mc-1fr xs">
      {showIcon && Icon}
      {!showIcon && (
        <div
          className={classNames('flex step-number with-margin', {
            ['active']: active,
          })}
        >
          <ServerSharedText type="label-s" color="body">
            {index}
          </ServerSharedText>
        </div>
      )}

      <div className="grid-row m justify-items-start">
        <div className="grid-row xxs">
          <div className="flex xs justify-content-start">
            <ServerSharedText type="label" color={getTitleColor(status)}>
              {t(title)}
            </ServerSharedText>

            {titleTime && (
              <ServerSharedText type="text-italic" color="grey500">
                {t(titleTime)}
              </ServerSharedText>
            )}
          </div>
          {showSubtitle && (
            <ServerSharedText color="grey500" as="pre" type="text-small">
              {typeof subtitle === 'string' ? t(subtitle) : subtitle}
            </ServerSharedText>
          )}
          {children}
        </div>
        {button}
      </div>
    </div>
  );
};
