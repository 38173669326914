export interface UserInvestmentAccount {
  holdingId: number;
  holdingRole: UserHoldingRole;
  id?: number;
  userId: number;
  walletDetails?: WalletDetails;
  holdingName: string;
}

export enum WalletCreationType {
  'GENERATED_VIA_TANGANY' = 'GENERATED_VIA_TANGANY',
  'MANUALLY' = 'MANUALLY',
}

export interface WalletDetails {
  address: string;
  investmentAccountId: number;
  status: WalletVerificationStatus;
  type: WalletCreationType;
  updatedAt: string;
  isWhiteListed: boolean; // TODO remove
}

export enum WalletVerificationStatus {
  'INVALID' = 'INVALID',
  'PENDING' = 'PENDING',
  'VERIFIED' = 'VERIFIED',
}

export enum UserHoldingRole {
  'AUTHORIZED_REPRESENTATIVE' = 'AUTHORIZED_REPRESENTATIVE',
  'CHAIRMAN_OF_THE_SUPERVISORY_BOARD' = 'CHAIRMAN_OF_THE_SUPERVISORY_BOARD',
  'ECONOMICALLY_ENTITLED_PERSON' = 'ECONOMICALLY_ENTITLED_PERSON',
  'EXECUTIVE_BOARD_MEMBER' = 'EXECUTIVE_BOARD_MEMBER',
  'INHERITOR' = 'INHERITOR',
  'MANAGING_DIRECTOR' = 'MANAGING_DIRECTOR',
  'SHAREHOLDER' = 'SHAREHOLDER',
  'SPOUSE' = 'SPOUSE',
  'SUPERVISORY_BOARD' = 'SUPERVISORY_BOARD',
}
