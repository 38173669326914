import { SVGProps, memo } from 'react';

const SvgCheckCirclePrimary24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM17.775 8.83333L12.0667 16.575C12.0002 16.6655 11.9162 16.7417 11.8196 16.7989C11.723 16.8562 11.6159 16.8933 11.5046 16.9081C11.3933 16.9229 11.2801 16.915 11.1719 16.885C11.0638 16.8549 10.9628 16.8034 10.875 16.7333L6.8 13.475C6.7144 13.4062 6.64323 13.3212 6.59058 13.2249C6.53794 13.1285 6.50486 13.0227 6.49324 12.9135C6.48163 12.8043 6.4917 12.6939 6.5229 12.5886C6.55409 12.4833 6.60578 12.3852 6.675 12.3C6.81348 12.132 7.01222 12.0248 7.2287 12.0014C7.44518 11.9781 7.6622 12.0404 7.83334 12.175L11.2333 14.8917L16.4333 7.83333C16.5645 7.65762 16.7597 7.54071 16.9766 7.50795C17.1934 7.47519 17.4144 7.52922 17.5917 7.65833C17.6817 7.7228 17.7579 7.80455 17.816 7.89882C17.8741 7.99309 17.9128 8.09798 17.9298 8.20737C17.9469 8.31676 17.942 8.42846 17.9154 8.53594C17.8888 8.64341 17.8411 8.74451 17.775 8.83333Z"
      fill="#2180CF"
    />
  </svg>
);

const Memo = memo(SvgCheckCirclePrimary24Px);
export default Memo;
